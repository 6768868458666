import { MenuController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MbowService } from 'src/app/services/mbow.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { CommonService } from 'src/app/services/common.service';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';
import * as moment from 'moment';
@Component({
  selector: 'app-userview-order',
  templateUrl: './userview-order.component.html',
  styleUrls: ['./userview-order.component.scss'],
})
export class UserviewOrderComponent implements OnInit {
  @Input() sendOrderViewObj: any;

  modal: HTMLElement;
  titleOrderShow: any;
  orderID: any;
  ionicStorage: any;
  allOrderInfo: any = []
  chequeNoOrder: any
  orderStatusPass: string;
  loaded: boolean = false
  outletCurrency;
  showCurrency: any = {};
  showPostedToPos: boolean = false
  orderType: any;
  popOverVal: number = 0;
  audioUrl: string;
  public _player: HTMLAudioElement;
  isPlaying = false;
  playsound: boolean = true;
  file: MediaObject
  sendTitleObj: any;
  enablePos: boolean;
  startDateOrders: any = moment().format('YYYY-MM-DD')
  constructor(
    private menu: MenuController,
    private modalController: ModalController,
    private router: Router,
    private mbow: MbowService,
    private appState: AppStateService,
    public common: CommonService,
    private navCtrl: NavController,
    public dashboardService: DashboardhomeService,
    private popoverController: PopoverController,
    public sanitizer: DomSanitizer,
    private media: Media,
    private zone: NgZone
  ) {

    this.outletCurrency = this.dashboardService.outletCurrency
    this.ionicStorage = this.appState.getAppData();
    this.ionicStorage.appInitData.currency.forEach(element => {
      if (this.outletCurrency == element.currencyName) {
        this.showCurrency = element;
      }

    });
    console.log(" this.showCurrency", this.showCurrency)
  }



  ngOnInit() {

    console.log("sendOrderViewObj", this.sendOrderViewObj)

    this.orderType = this.sendOrderViewObj.screenName
    this.orderID = this.sendOrderViewObj.orderid;
    this.titleOrderShow = this.sendOrderViewObj.sendTitle
    console.log(" this.orderType``````", this.orderType)
    if (this.orderType == 'Orders to be Booked') {
      console.log("===><<>>>", this.orderStatusPass)
      this.orderStatusPass = 'Posted to POS'
    }

    else {
      this.orderStatusPass = 'Completed'
      console.log("34234324", this.orderStatusPass)
    }



  }


  ngAfterContentInit() {
    this.menu.enable(false);
    // this.plt.ready().then((readySource) => {
    //   this.translate.get('Order ID: XXXXXXXXXX').subscribe((res: string) => {
    //     this.events.publish('title', res);
    //   });
    // });
    this.getUserHistory();
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: this.modal,
      mode: 'ios',
      initialBreakpoint: 0.3,
      breakpoints: [0, 0.3, 1],
      handle: false,
    });
    return await modal.present();
  }

  navigationBack() {
    this.navCtrl.setDirection('back');
    this.router.navigate(['orders'])
  }

  getUserHistory() {
    this.mbow.memberOrderDetails(this.ionicStorage.userDetails.outletId, this.orderID).subscribe(res => {
      if (res) {
        this.allOrderInfo = res;
        this.loaded = true;
        this.showPostedToPos = true
        this.audioUrl = this.allOrderInfo.deliveryAddress.specialInstructions.audio;
        // let orderDateOne = (new Date(this.allOrderInfo.deliveryDate)).getTime();
        // let orderDateTwo = new Date(orderDateOne);
        // let orderTree = new Date(orderDateTwo.getFullYear(),orderDateTwo.getMonth(),orderDateTwo.getDate())
        // let today = new Date().getTime()
        // let todayDate = new Date(today)
        // console.log("year", orderTree.getFullYear())
        // console.log("orderTree.getDate()", orderTree.getMonth())
        // console.log("todayDate.getDate()", todayDate.getMonth())
        // if (orderTree.getFullYear() >= todayDate.getFullYear()) {
        //   console.log("hit1")
        //   if (orderTree.getMonth()+1 >= (todayDate.getMonth()+1)) {
        //     console.log("hit 2")
        //     if (orderTree.getDate() >= todayDate.getDate()) {
        //       console.log("hit 3")
        //         this.enablePos = true
        //     }
        //   }
        // }

        console.log(this.allOrderInfo.deliveryDate + "T23:59:59.999Z", moment(this.allOrderInfo.deliveryDate + "T00:00:00.000Z").toISOString(), moment().toISOString())
        if (moment(this.allOrderInfo.deliveryDate + "T23:59:59.999Z").isSameOrAfter(moment())) {
          this.enablePos = true
          if (this.allOrderInfo.chequeNumber) {
            this.chequeNoOrder = this.allOrderInfo.chequeNumber
            this.orderStatusPass = 'Completed'
          } else {
            this.orderStatusPass = 'Posted to POS'
          }

        } else {
          console.log("====+++++___+++")
        }

      }

    }, err => {
      this.showPostedToPos = false
      this.loaded = true;
      this.common.errorHandler(err);
    })
  }

  confirmCheckNo() {
    this.common.presentLoader()
    this.mbow.checkNOorderApi(this.ionicStorage.userDetails.outletId, this.chequeNoOrder, this.allOrderInfo.orderId, this.orderStatusPass).subscribe(res => {
      if (res) {
        this.common.dismissLoader()
        this.modalController.dismiss()
        this.common.presentToast(res.status);
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
    }, err => {
      this.common.dismissLoader()
      this.common.errorHandler(err);
    })
  }

  onScroll() {
    if (this.popOverVal == 1) {
      this.popoverController.dismiss();
      this.popOverVal = 0
    }
  }

  ionViewWillLeave() {
    this.onScroll();
    if (this.file) {
      this.file.stop()
      this.file.release()
    }
  }

  popover(event: any) {
    // con ̰sole.log("popover called", event);
    this.popOverVal = this.popOverVal + 1;
    if (this.popOverVal >= 1) {
      this.popoverController.dismiss();
      event.stopPropagation();
      this.popOverVal = 1
    }
  }

  contentclicked() {
    if (this.popOverVal == 1) {
      this.popOverVal = this.popOverVal + 1;
      this.popoverController.dismiss();
      this.popOverVal = 0
    }
  }


  playerAudio(file) {
    file.onStatusUpdate.subscribe(status => {
      console.log("status audio =>>", status)

    }) // fires when file status changes
    file.onSuccess.subscribe(() => {
      console.log('Action is successful')
      this.zone.run(() => {
        this.file = null;
        this.isPlaying = false
      })


    });
    file.onError.subscribe(error => console.log('Error!', error));
  }

  playAudio(event): void {
    if (event == 'play') {
      console.log("inside play")
      if (!this.file)
        this.file = this.media.create(this.audioUrl);
      this.file.play();
      this.isPlaying = true;

    } else if (event == 'pause') {
      console.log("inside pause")
      this.file.pause();
      this.isPlaying = false;

    }
    this.playerAudio(this.file)
  }


}
