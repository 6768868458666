
import { Component, Input, OnInit, Output } from '@angular/core';
import { ModalController, MenuController, Platform } from '@ionic/angular';
import { ReservationDetailsComponent } from 'src/app/modals/reservation-details/reservation-details.component';
import { ReservationRegretComponent } from 'src/app/modals/reservation-regret/reservation-regret.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { CommonService } from 'src/app/services/common.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { constManageReservationPage, TITLE, MANAGE_RESERVATION_PAGE, JAVASCRIPT, COUNT_NUMBER, MONTHS, REVIEW_HISTORY_PAGE, RESERVATION_DETAILS_MODAL, COUNT, REGEX } from 'src/app/app.constants';
import { ProfileService } from 'src/app/services/profile.service';
import { EventService } from 'src/app/services/event/event.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-manage-reservation',
  templateUrl: './manage-reservation.component.html',
  styleUrls: ['./manage-reservation.component.scss'],
})
export class ManageReservationComponent implements OnInit {

  @Input() sendSfid: any;
  data: any;
  reservationType: any;
  reservationSfid: any;
  manageResTypePage: any = MANAGE_RESERVATION_PAGE.MANAGE_RESERVATION;
  typeManage: { confirmed: string, noShow: string }
  responseReservationData: any;
  resevationStatusType
  ionicStorageData: any;
  manageStoreData: any;
  showGuestDiv: boolean;
  rejectReservationReason: any;
  skelton: boolean = true;
  allModalData: any;
  tableNo: any
  hotelContent: any;
  manageReservationTable: any;
  membershipNo: any;
  reserveId: any;
  benefitId: boolean;
  tabledetails: string;
  currentDate: number;
  reedeemData: any;
  checkNo: any;
  reservationDate: string;
  reservationRegreatReason: any;
  guestCount: number = 1
  childCount: number = 0
  adultCount: number = 1;
  netAmount: string;
  amountEnable: boolean = false;
  showCurrency: any;
  notDisabledCheckNo: boolean;
  modifyRes: any;
  notDisabledTable: boolean;
  tableStatus: boolean;
  reservationConfirmationPara: any;
  outletCurrency: any;
  popoverOptions = {
    showBackdrop: false,
    dismissOnSelect: true,
    backdropDismiss: true,
    keepContentsMounted: true,
    side: "bottom",
    alignment: "start",
    mode: "md",
    size: "cover",
  };
  allVaccanTable: any[];
  newTableNumberDetail: any;
  tableNumberNew: any;
  eanbleModifiyTable: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    public router: Router,
    public events: EventService,
    public plt: Platform,
    private translate: TranslateService,
    public menu: MenuController,
    public activatedRoute: ActivatedRoute,
    private reservationService: DashboardhomeService,
    public common: CommonService,
    public appState: AppStateService,
    public profileService: ProfileService
  ) {
    this.outletCurrency = this.reservationService.outletCurrency
    this.activatedRoute.queryParams.subscribe((res) => {
      console.log("idd=>reser", res)
      this.reservationSfid = res.id
    });
  }

  ngAfterContentInit() {
    this.currentDate = new Date().getTime();
    this.reservationType = this.reservationService.reservationsTypeData
    this.manageReservationTable = this.reservationService.reservationTable
    this.plt.ready().then((readySource) => {
      this.menu.enable(false)
      this.translate.get(MANAGE_RESERVATION_PAGE.MANAGE_RESERVATION_TITLE).subscribe((res: string) => {
        this.events.publish(TITLE.TITLE, res);
      });
    });
    this.reservtionUserDetils();
    this.allTableList()
  }

  ngOnInit() {

    this.reservationSfid = this.sendSfid
    this.reservationType = this.reservationService.reservationsTypeData
    this.typeManage = constManageReservationPage
    this.manageStoreData = this.appState.getAppData();
  }



  async openModal(type: string) {
    if (type === MANAGE_RESERVATION_PAGE.MODIFY) {
      this.modifyFunc();
    } else if (type === MANAGE_RESERVATION_PAGE.REGRET) {
      this.regretFunc();
    }
  }
  async modifyFunc() {
    document.getElementById(MANAGE_RESERVATION_PAGE.modifyModal).classList.remove(JAVASCRIPT.SHOW);
    let modal;
    modal = await this.modalCtrl.create({
      component: ReservationDetailsComponent, componentProps: {
        'reservationDetailsSend': this.responseReservationData
      }
    });
    modal.present();
    const modifyModalData = await modal.onWillDismiss();
    this.allModalData = modifyModalData.data
    if (modifyModalData.data) {
      //console.log("datamodify",modifyModalData.data)
      var hour = new Date().getHours()
      let minutes: any = new Date().getMinutes();
      minutes = minutes > 9 ? minutes : '0' + minutes;
      console.log("hour", hour)
      console.log("minutes", minutes)
      var todayDate = new Date().toISOString().slice(0, 10);
      console.log("todayDate", todayDate)
      var systemTime = hour + ':' + minutes
      console.log("systemTime", systemTime)
      if (modifyModalData.data.modifyModalData) {
        console.log(" this.allModalData", this.allModalData)
        if (modifyModalData.data.modifyModalData.date === todayDate) {
          var reservationTime = String(modifyModalData.data.modifyModalData.time + ':' + modifyModalData.data.modifyModalData.minute)
          if (reservationTime > systemTime) {
            modifyModalData.data
            this.skelton = true;
            this.allModalData = modifyModalData.data
            this.modifyApi();
          } else {
            console.log("MODAL open ")
            document.getElementById('disabledPastDate').classList.add(JAVASCRIPT.SHOW);
          }

        }
        else {
          modifyModalData.data
          this.skelton = true;
          this.allModalData = modifyModalData.data
          this.modifyApi();
        }
      }
      else {

      }


    }
  }

  async regretFunc() {
    let modal;
    modal = await this.modalCtrl.create({
      component: ReservationRegretComponent, componentProps: {
        'reservationSfid': this.reservationSfid,
      }
    });
    modal.present();
    const reservationRegreatReason = await modal.onWillDismiss();
    if (reservationRegreatReason.data) {
      this.reservationRegreatReason = reservationRegreatReason.data.reservationRegreatReason;
      // this.regrettedApi();
      this.regrettedApiModal();
    }
  }

  buffetClick(info) {
    if (this.responseReservationData.benefitType == MANAGE_RESERVATION_PAGE.Certificate) {
      this.profileService.infoData = info;
      this.profileService.checkCertificateScreen = MANAGE_RESERVATION_PAGE.manageReservation;
      this.router.navigate(['certificate-detail']);
    }
  }

  goToGuestProfile(memberShipNO) {
    console.log("goToGuestProfile", memberShipNO)
    this.profileService.membershipNumber = memberShipNO
    if (this.profileService.membershipNumber) {
      this.skelton = true;
      this.profileService.customerPersonalDetails = {
        'mobile': this.responseReservationData.memberDetails.mobile,
        'email': this.responseReservationData.memberDetails.email,
      }
      this.router.navigate(['guest-profile']);
    }

  }

  // ******* Modify Api ****** 
  modifyApi() {
    this.reservationService.modifyApifunction(this.responseReservationData.reservationId,
      this.manageStoreData.userDetails.outletId, this.allModalData, this.manageStoreData.userDetails.outletType).subscribe(res => {
        if (res) {
          document.getElementById('updatemodify').classList.add(JAVASCRIPT.SHOW);
          this.modifyRes = res
          this.reservtionUserDetils()
        }
        else {
          this.skelton = false;
        }
      }, err => {
        this.skelton = false;
        this.common.errorHandler(err)

      })
  }

  //** ReservationDetails Api */
  reservtionUserDetils() {
    this.reservationService.allReservationApi(JAVASCRIPT.BLANK, JAVASCRIPT.BLANK, JAVASCRIPT.BLANK, this.reservationSfid, this.manageResTypePage, JAVASCRIPT.BLANK, JAVASCRIPT.BLANK).subscribe(res => {
      if (res) {
        console.log("resmanage=>>", res)
        this.skelton = false;
        this.responseReservationData = res
        this.manageReservationTable = this.responseReservationData.reservationTableNo
        var month = new Array();
        month[COUNT_NUMBER.COUNT_0] = MONTHS.january;
        month[COUNT_NUMBER.COUNT_1] = MONTHS.february;
        month[COUNT_NUMBER.COUNT_2] = MONTHS.march;
        month[COUNT_NUMBER.COUNT_3] = MONTHS.april;
        month[COUNT_NUMBER.COUNT_4] = MONTHS.may;
        month[COUNT_NUMBER.COUNT_5] = MONTHS.june;
        month[COUNT_NUMBER.COUNT_6] = MONTHS.july;
        month[COUNT_NUMBER.COUNT_7] = MONTHS.august;
        month[COUNT_NUMBER.COUNT_8] = MONTHS.september;
        month[COUNT_NUMBER.COUNT_9] = MONTHS.october;
        month[COUNT_NUMBER.COUNT_10] = MONTHS.november;
        month[COUNT_NUMBER.COUNT_11] = MONTHS.december;
        var day = new Date(this.responseReservationData.reservationDate);
        var currentMonth = month[day.getMonth()];
        var currentArr = this.responseReservationData.reservationDate.split('-')
        this.translate.get(REVIEW_HISTORY_PAGE.months + currentMonth).subscribe((res: string) => {
          this.reservationDate = currentArr[COUNT_NUMBER.COUNT_2] + JAVASCRIPT.BLANKSPACE + res;
        });
        if (this.responseReservationData.reservationStatus == MANAGE_RESERVATION_PAGE.In_Progress || this.responseReservationData.reservationStatus == MANAGE_RESERVATION_PAGE.Redeemed && this.responseReservationData.benefitCode != JAVASCRIPT.BLANK) {
          if (this.manageStoreData.userPageAccess.userPageAccess.includes(MANAGE_RESERVATION_PAGE.GET_REDEEM_TRXN)) {
            this.redeemTransaction();
          }
        }
      }
      else {
        this.skelton = false;
      }
    }, err => {
      this.common.errorHandler(err);

    })
  }

  regrettedApiModal() {
    document.getElementById(MANAGE_RESERVATION_PAGE.regrettedModal).classList.add(JAVASCRIPT.SHOW);
  }

  // ...... REGRET API ........
  regrettedApi() {
    this.common.presentLoader();
    this.reservationService.reservationChangeStatus(JAVASCRIPT.BLANK, this.reservationSfid, this.manageStoreData.userDetails.employeeId, this.reservationRegreatReason).subscribe(res => {
      this.common.dismissLoader();
      if (res) {
        document.getElementById(MANAGE_RESERVATION_PAGE.regrettedModal).classList.remove(JAVASCRIPT.SHOW);
        // this.router.navigate(['web-dashboard']);
        window.location.reload();
      }
      else {
        this.common.dismissLoader();
      }
    }, err => {
      this.common.dismissLoader();
      this.common.errorHandler(err);
    })
  }


  //..Api of the confirmation of the table
  editReservationTable() {
    if (this.responseReservationData.reservationTableNo) {
      this.reallocatedApi()
      return;
    }
    this.common.presentLoader();
    // this.reservationService.allotReservartionApi(this.reservationSfid, this.tabledetails || this.tableNo,
    //   this.manageStoreData.userDetails.employeeId, JAVASCRIPT.BLANK, this.manageStoreData.userDetails.outletType, this.responseReservationData.checkNo).subscribe(res => {

    this.reservationService.assignTable(this.tableNo, this.responseReservationData, this.manageStoreData.userDetails.sfid, this.checkNo || this.responseReservationData.checkNo,).subscribe(res => {
      this.common.dismissLoader();
      if (res) {
        this.eanbleModifiyTable = false;
        this.reservtionUserDetils();
        this.allTableList()
        // this.manageReservationTable = this.tableNo
        this.manageReservationTable = String(this.newTableNumberDetail)
        document.getElementById(MANAGE_RESERVATION_PAGE.editReservation).classList.remove(JAVASCRIPT.SHOW);
      }
      else {
        this.common.dismissLoader();
        document.getElementById(MANAGE_RESERVATION_PAGE.editReservation).classList.remove(JAVASCRIPT.SHOW);
      }
    }, err => {
      this.common.dismissLoader();
      this.common.errorHandler(err);
    })
  }
  editReservationModalClose() {
    this.tableNo = JAVASCRIPT.BLANK
    this.eanbleModifiyTable = false;
    this.tableNumberNew = null
    document.getElementById(MANAGE_RESERVATION_PAGE.editReservation).classList.remove(JAVASCRIPT.SHOW);
  }

  openConfimModalFuntion() {
    document.getElementById(MANAGE_RESERVATION_PAGE.confirmationModal).classList.add(JAVASCRIPT.SHOW);
  }

  reserveStatusChangeApi(statusReservation) {
    this.common.presentLoader();
    this.reservationService.resevationStatusType = statusReservation
    this.reservationService.reservationChangeStatus(statusReservation, this.reservationSfid, this.manageStoreData.userDetails.employeeId, this.reservationRegreatReason).subscribe(res => {

      this.common.dismissLoader();
      if (res) {
        this.modifyRes = res
        if (this.reservationType == MANAGE_RESERVATION_PAGE.AC) {
          document.getElementById(MANAGE_RESERVATION_PAGE.confirmationModal).classList.remove(JAVASCRIPT.SHOW);
          document.getElementById('updatemodify').classList.add(JAVASCRIPT.SHOW);


        } else if (this.reservationType == MANAGE_RESERVATION_PAGE.AA) {
          document.getElementById(MANAGE_RESERVATION_PAGE.noShowModal).classList.add(JAVASCRIPT.SHOW);

        }
      }
    }, err => {
      this.common.dismissLoader();
      this.common.errorHandler(err);
    })
  }

  openModalCheque(Userdata) {
    this.manageStoreData.appInitData.currency.forEach(element => {
      if (this.outletCurrency == element.currencyName) {
        this.showCurrency = element;
      }

    });
    console.log(" this.showCurrency", this.showCurrency)
    console.log("userDataa=>>", Userdata)
    this.guestCount = Userdata.guestCount
    this.adultCount = Userdata.adultCount
    this.childCount = Userdata.kidsCount

    if (Userdata.checkNo == '') {
      this.notDisabledCheckNo = false
    } else {
      this.checkNo = Userdata.checkNo
      this.notDisabledCheckNo = true

    } if (Userdata.reservationTableNo == '') {
      this.notDisabledTable = false
    } else {
      this.tableNo = String(this.manageReservationTable)
      this.notDisabledTable = true
    }
    this.tableNo = String(this.manageReservationTable)

    document.getElementById('alreadyTable').classList.add(JAVASCRIPT.SHOW);

  }

  // close cheque api 
  closeChequeApi() {
    
    this.common.presentLoader();
    this.reservationService.closeChequeApiFuntion(this.manageStoreData.userDetails.employeeId, this.reservationSfid, this.responseReservationData.memberDetails.membershipNumber, this.guestCount, this.adultCount, this.childCount, this.manageStoreData, this.netAmount, this.showCurrency).subscribe(res => {
      this.common.dismissLoader();
      if (res) {
        this.modifyRes = res
        document.getElementById(MANAGE_RESERVATION_PAGE.closeCheque).classList.remove(JAVASCRIPT.SHOW);
        document.getElementById('updatemodify').classList.add(JAVASCRIPT.SHOW);
        // this.router.navigate(['web-dashboard']);

      }
      else {
        this.common.dismissLoader();
      }
    }, err => {
      this.common.dismissLoader();
      this.common.errorHandler(err);
    })
  }

  closeChequeModal() {
    this.netAmount = '';
    this.amountEnable = false;
    this.checkNo = ''
    this.notDisabledCheckNo = false;
    this.tableNo = ''
    document.getElementById(MANAGE_RESERVATION_PAGE.closeCheque).classList.remove(JAVASCRIPT.SHOW);
  }

  tableEditFun() {
    this.tableNo = String(this.manageReservationTable)

    document.getElementById(MANAGE_RESERVATION_PAGE.editReservation).classList.add(JAVASCRIPT.SHOW);
  }

  regretGotIt() {
    document.getElementById(MANAGE_RESERVATION_PAGE.regrettedModal).classList.remove(JAVASCRIPT.SHOW);
  }

  confirmationClose() {
    document.getElementById(MANAGE_RESERVATION_PAGE.confirmationModal).classList.remove(JAVASCRIPT.SHOW);
  }

  cancelCloseChequeModal() {
    document.getElementById(MANAGE_RESERVATION_PAGE.cancelCloseCheque).classList.add(JAVASCRIPT.SHOW);
  }
  removeCloseChequeModal() {
    document.getElementById(MANAGE_RESERVATION_PAGE.cancelCloseCheque).classList.remove(JAVASCRIPT.SHOW);
  }

  guestShowHide() {
    this.showGuestDiv = !this.showGuestDiv
  }

  toggleId() {
    this.hotelContent = !this.hotelContent;
  }

  confirmNoShow() {
    window.location.reload();
  }

  /* redeemTransaction api integrated */
  redeemTransaction() {
    if (this.responseReservationData.benefitType == 'promocode') {
      return;
    }
    this.reservationService.redeemTransaction(this.reservationSfid, this.responseReservationData.reservationId, this.responseReservationData.benefitCode).subscribe((res) => {
      if (res) {
        this.reedeemData = res
      }
    })
  }

  //* to open the popup */
  // async openModifyModal() {
  //   // let header;

  //   const alert = await this.alertController.create({
  //     // header: header,
  //     message: MANAGE_RESERVATION_PAGE.MESSAGE,
  //     mode: 'ios',
  //     cssClass: 'alertCustomCss',
  //     buttons: [
  //       {
  //         text: MANAGE_RESERVATION_PAGE.CANCEL,
  //         role: 'cancel',
  //         handler: (blah) => {
  //         }
  //       }, {
  //         text: MANAGE_RESERVATION_PAGE.CONTINUE,
  //         cssClass: 'buttonCss',
  //         handler: () => {
  //           this.modifyFunc()
  //         }
  //       }
  //     ]
  //   });
  //   await alert.present();
  // }
  openModifyModal() {
    document.getElementById(MANAGE_RESERVATION_PAGE.modifyModal).classList.add(JAVASCRIPT.SHOW);
  }

  closeModifyModal() {
    document.getElementById(MANAGE_RESERVATION_PAGE.modifyModal).classList.remove(JAVASCRIPT.SHOW);

  }

  cancelCloseChequqGotIt() {
    this.common.presentLoader();
    this.reservationService.cancelCloseChequqApi(this.manageStoreData.userDetails.outletId, this.responseReservationData.reservationId,
      this.responseReservationData.benefitCode, this.responseReservationData.memberDetails.membershipNumber).subscribe((res) => {
        this.common.dismissLoader();
        if (res) {
          document.getElementById(MANAGE_RESERVATION_PAGE.cancelCloseCheque).classList.remove(JAVASCRIPT.SHOW);
          // this.router.navigate(['in-progress']);
          window.location.reload();

        }
        else {
          this.common.dismissLoader();
        }
      }, err => {
        this.common.dismissLoader();
        this.common.errorHandler(err);
      })
  }

  gotItPastDateModal() {
    document.getElementById('disabledPastDate').classList.remove(JAVASCRIPT.SHOW);
  }

  addCounter(outletType, member) {
    console.log("addCounter")

    if (outletType == RESERVATION_DETAILS_MODAL.RESTAURANT) {

      if (member == RESERVATION_DETAILS_MODAL.GUEST) {
        this.guestCount += parseInt(COUNT.COUNT_1);
      }
    }
    else if (outletType == RESERVATION_DETAILS_MODAL.POOL) {
      if (member == RESERVATION_DETAILS_MODAL.CHILD) {
        this.childCount += parseInt(COUNT.COUNT_1);
      }
      else if (member == RESERVATION_DETAILS_MODAL.ADULT) {
        this.adultCount += parseInt(COUNT.COUNT_1);
      }
    }
  }
  onDecrement(outletType, member) {
    console.log("onDecrementhiyyx1")

    if (outletType == RESERVATION_DETAILS_MODAL.RESTAURANT) {
      if (member == RESERVATION_DETAILS_MODAL.GUEST) {
        if (this.guestCount != parseInt(COUNT.COUNT_0)) {
          this.guestCount -= parseInt(COUNT.COUNT_1);
        }
      }
    }
    else if (outletType == RESERVATION_DETAILS_MODAL.POOL) {
      if (member == RESERVATION_DETAILS_MODAL.CHILD) {
        if (this.childCount != parseInt(COUNT.COUNT_0)) {
          this.childCount -= parseInt(COUNT.COUNT_1);
        }
      }
      else if (member == RESERVATION_DETAILS_MODAL.ADULT) {
        if (this.adultCount != parseInt(COUNT.COUNT_0)) {
          this.adultCount -= parseInt(COUNT.COUNT_1);

        }
      }
    }
  }

  alreadyTablecloseModal() {
    this.netAmount = '';
    this.amountEnable = false;
    this.checkNo = '';
    this.tableNo = '';
    document.getElementById('alreadyTable').classList.remove(JAVASCRIPT.SHOW);

  }

  confirmModalAreadyAssign() {
    document.getElementById('alreadyTable').classList.remove(JAVASCRIPT.SHOW);

    document.getElementById(MANAGE_RESERVATION_PAGE.closeCheque).classList.add(JAVASCRIPT.SHOW);

  }



  onChange(e) {
    if (e.target.value == 0 || e.target.value < 0) {
      const pattern = REGEX.onlyNumberValidate;
      let curStr = e.target.value;
      e.target.value = curStr.replace(pattern, '');
    }
    var regexp = /^\d+(\.\d{1,2})?$/;
    let curStr = e.target.value;
    console.log(regexp.test(curStr));
    if (!regexp.test(curStr)) {
      this.amountEnable = false
    }
    else {
      this.amountEnable = true
      console.log("valid");
    }

  }

  check() {
    if (this.netAmount == '') {
      this.amountEnable = false;
    }
  }

  proceedUpdate() {
    if (this.responseReservationData.reservationStatus == 'Awaiting Confirmation') {
      window.location.reload()
    }
    document.getElementById('updatemodify').classList.remove(JAVASCRIPT.SHOW);
    window.location.reload()
  }

  // checkTableInput(tableNumber) {
  //   console.log("tableNumber", tableNumber)
  //   // this.newTableNumberDetails  =  
  //   this.tableNo
  // }

  UpdateTableInput(tableNumberr) {
    console.log("UpdateTableInput", tableNumberr)
    this.newTableNumberDetail = tableNumberr.tableNum
    this.tableNo = tableNumberr.tableSfid
    this.eanbleModifiyTable = true 

  }

  numberOnly(event: any) {
    let theEvent = event || window.event,
      key = theEvent.target.value,
      regex = /[0-9]+/g;
    // not remove this console 
    console.log("regex test", regex.test(key));
    if (!regex.test(key)) {
      let resp = event.target.value.match(regex)
      event.target.value = resp ? resp.join('') : ''
    }
  }

  allTableList() {
    this.reservationService.tableAllotmentTypeApi(this.manageStoreData.userDetails.sfid, this.manageStoreData.userDetails.outletId, 'All').subscribe(res => {
      this.allVaccanTable = []
      if (res) {
        res.data.forEach(element => {
          if (element.available == true) {
            this.allVaccanTable.push(element)

          }
        });

      }
    })

  }

  reallocatedApi() {
    this.skelton = true;
    this.common.presentLoader()
    this.reservationService.postReaasignmanage(this.tableNo, this.responseReservationData,this.manageStoreData.userDetails.sfid).subscribe(res => {
      if (res) {
        this.eanbleModifiyTable = false;
        this.common.dismissLoader()
        this.allTableList()
        this.reservtionUserDetils();
        this.manageReservationTable = String(this.newTableNumberDetail)
        document.getElementById(MANAGE_RESERVATION_PAGE.editReservation).classList.remove(JAVASCRIPT.SHOW);


      }
    }, err => {
      this.skelton = false;
      this.common.dismissLoader()
      this.common.errorHandler(err);
    })
  }

  tableReady() {
    this.common.presentLoader();
    this.reservationService.tableReadyApi(this.responseReservationData.reservationId,this.manageStoreData.userDetails.outletId).subscribe((res) => {
      if (res) {
        this.common.dismissLoader();
        this.common.customAlert(res.status);
      }
    }, err => {
      this.common.dismissLoader();
      this.common.errorHandler(err);
    }) 
  }


}
