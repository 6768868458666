import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpWrapperService, HttpInputData } from './core/http/http-wrapper.service';
import { appApiResources } from './../app.constants';
import { Item } from 'src/data/ItemList.model';
import { Widget } from 'src/data/WidgetList.model';
import { environment } from 'src/environments/environment';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
// import { Camera } from '@ionic-native/camera';
import { AppStateService } from './init/app-state.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './common.service';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { RevenueGet } from 'src/data/revenue.model';
import da from '@mobiscroll/angular/dist/js/i18n/da';
import { LocalizationService } from './core/localize/localization.service';
import { JAVASCRIPT } from 'src/app/app.constants';
import { Platform } from '@ionic/angular';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { options } from '@mobiscroll/angular';

@Injectable({
  providedIn: 'root'
})
export class MbowService {
  sessionid: any;
  memberid: any;
  csrftoken: any;
  imgUrl: any;
  loaderText: any;
  appData: any;
  outletData: any;
  constructor(
    public httpWrapper: HttpWrapperService,
    public camera: Camera,
    private appState: AppStateService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private file: File,
    private fileTransfer: FileTransfer, 
    private localizationService: LocalizationService,
    private platform: Platform,
    private cookieService: CookieService,
    private http:HttpClient
  ) {
    this.appData = this.appState.getAppData();
  }

  orderDetailsApi(outletId, type,bucketNumber?: any,bucketSize?:any) {
    if(bucketSize =='undefined'||bucketSize == undefined ||bucketSize ==null ) {
      bucketSize = 10
    } else {
      bucketSize = 10
    }
    let httpParams = new HttpParams();
    let options = new HttpInputData();
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/orders?type=${type}`, options)
      .map((response: any) => {
        return response;
      });
  }

  mbowUserDetails(memberId) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.get(`member/${memberId}/orders`, options)
      .map((response: any) => {
        return response;
      })
  }


  memberOrderDetails(sfid, orderid) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${sfid}/orders/${orderid}`, options)
      .map((response: any) => {
        return response;
      })
  }

  getDataforMenuSetup(outletId) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/setupMenu`, options)
      .map((response: any) => {
        console.log("response for the menusetup");
        return response;
      })

  }

  // Setup out API to fetch get data on init of page
  setupOutletApi(outletId) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/setupoutlet`, options)
      .map((response: any) => {
        this.outletData = response;
        return response;
      })
  }

  Menu(outletId) {
    console.log("outletid", outletId);
    let options = new HttpInputData();
    let params = {};
    params['getPublishItems'] = true;
    params['isItemRequired'] = true;
    let httpParams = new HttpParams({ fromObject: params });
    
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/getWidgets`, options)
      .map((Response: any) => {
        return Response;
      })

  }

  setupMenuPost(outletId, data: any) {
    console.log("setupmenu function called for the post data")
    let options = new HttpInputData();
    return this.httpWrapper.post(`outlet/${outletId}/setupoutlet`, data, options)
      .map((res: any) => {
        console.log("response for the setupmenupost", res);
      })

  }



  setupOutletPost(outletId, Data: any) {

    console.log("setupoutpost api called ==>>>", Data);
    let options = new HttpInputData();
    return this.httpWrapper.post(`outlet/${outletId}/setupoutlet`, Data, options)
      .map((response: any) => {
        console.log("response", response);
        return response;
      });
  }

  checkNOorderApi(sfid, checkNo, orderId, status) {
    let options = new HttpInputData();
    let data =
    {
      "orderId": orderId,
      "checkNumber": checkNo,
      "status": status
    }

    return this.httpWrapper.post(`outlet/${sfid}/sendNotification`, data, options)
      .map((response: any) => {
        return response;
      })
  }


  widgetGetAPI(outletId) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/setupWidget`, options)
      .map((response: any) => {
        return response;
      })
  }


  widgetPost(outletId, data) {
    let options = new HttpInputData();

    return this.httpWrapper.post(`outlet/${outletId}/setupWidget`, data, options)
      .map((response: any) => {
        return response;
      })
  }


  itemAPI(outletId , param? : any,widgetId?:any ) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    if(param){
      console.log("his.param ==>>",param)
      
        // https://hotelapp-dev-int.tlcgroup-test-platform.com/api/outlet/a0Q9I000000zQK9UAM/itemAvailability?viewAll=true
      return this.httpWrapper.get(`outlet/${outletId}/itemAvailability?getAllMenu=${param}`, options)
        .map((response: any) => {
          return response;

        });
    }
    else {
      console.log("his.widgetId else ==>>",widgetId)
      return this.httpWrapper.get(`outlet/${outletId}/itemAvailability?widgetSfId=${widgetId}`, options)
      .map((response: any) => {
        return response;

        });
    }

  }
  /**
   * To update item's list from the server on the basis of the Outlet-ID.
   * @param outletId 
   * @param payload
   * @returns 
   */
   updateItem(outletId: any, payload: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.put(`outlet/${outletId}/editMenu`, payload, options)
      .map((response: any) => {
        return response;
      });
  }


  deleteItem(id, outletId) {
     let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    console.log("id here=>",id)
    // const httpOptions = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: id
    // };
    return this.httpWrapper.put(`outlet/${outletId}/itemAvailability`, id)
      .map((response: any) => {
        return response;
      });

  }
  



  /**
   * To get the setuprules from the server on the basis of the Outlet-ID.
   * @param outletId 
   * @returns 
   */
  getSetupRules(outletId: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/setuprules`, options)
      .map((response: any) => {
        console.log("Selected Outlet SetupRules::>", response);
        // return JSON.parse('{"delivery":{"deliveryTimes":[{"days":[3,4,6,0],"startTime":"12:40:00","endTime":"12:50:00"}],"deliveryFee":true,"deliveryFeeDetails":[{"pincode":"a249I000000L9d0QAC","fee":50,"timeValidity":10,"currency":"undefined"},{"pincode":"a249I000000L9d1QAC","fee":50,"timeValidity":10,"currency":"undefined"},{"pincode":"a249I000000LTLaQAO","fee":50,"timeValidity":10,"currency":"undefined"},{"pincode":"a249I000000LTLZQA4","fee":50,"timeValidity":10,"currency":"undefined"},{"pincode":"a249I000000LTNCQA4","fee":50,"timeValidity":10,"currency":"undefined"},{"pincode":"a249I000000LTNBQA4","fee":50,"timeValidity":10,"currency":"undefined"},{"pincode":"a249I000000L7svQAC","fee":50,"timeValidity":10,"currency":"undefined"}],"minimumOrderValue":565,"convenienceFee":true,"convenienceCharges":45,"notificationDelivery":true,"notificationDeliveryDetails":{"email":"pk@gmail.com","contactNumber":"254731123006"},"acceptableCancellationWindow":34,"minimumDeliveryTime":10,"quickDeliveryTime":10},"takeAway":{"takeAwayTime":[{"days":[1,3,4,0],"startTime":"12:42:00","endTime":"22:52:00"}],"acceptableCancellationWindow":45,"takeAwayTimeSelected":10,"quicktakeAwayTimeSelected":10,"pickUpLocation":"new delhi"},"inRoomDining":{"inRoomDiningTime":[{"days":[1,3,4,0],"startTime":"12:41:00","endTime":"22:51:00"}],"acceptableCancellationWindow":null,"inRoomDinningWindow":10},"rules":{"deliveryEnabled":true,"inRoomDiningEnabled":true,"takeAwayEnabled":true}}');
        return response;
      });
  }

  /**
   * To get the setuprules from the server on the basis of the Outlet-ID.
   * @param data - JSON object to be posted 
   * @param outletId 
   * @returns 
   */
  postSetupRules(data: any, outletId: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.post(`outlet/${outletId}/setuprules`, data, options)
      .map((response: any) => {
        return response;
      });
  }

  /**
   * To get the setuprules from the server on the basis of the Outlet-ID.
   * @param data - JSON object to be posted 
   * @param outletId 
   * @returns 
   */
  postRevenueManagementData(data: any, outletId: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    console.log("data from mbow service", data)
    return this.httpWrapper.post(`outlet/${outletId}/revenueManagement`, data, options)
      .map((response: any) => {
        return response;
      });
  }

  putRevenueManagementData(data: any, outletId: any){
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    console.log("data from mbow service", data)
    return this.httpWrapper.put(`outlet/${outletId}/revenueManagement`, data, options)
      .map((response: any) => {
        return response;
      });

  }

  /**
   * To get the updated item's list from the server on the basis of the Outlet-ID.
   * @param outletId 
   * @returns 
   */
  getUpdateItems(outletId: any, params?: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams({ fromObject: params });
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/getWidgets`, options)
      .map((response: any) => {
        return response.map((e: any) => new Item(e));
      });
  }

  

  /**
   * To add item 
   * @param outletId 
   * @param payload
   * @returns 
   */
  addItem(outletId: any, payload: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.post(`outlet/${outletId}/setupMenu`, payload, options)
      .map((response: any) => {
        return response;
      });
  }

  /**
   * To post the data of block services
   * @param outletId 
   * @param payload
   * @returns 
   */
  postBlockServices(outletId: any, payload: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.post(`outlet/${outletId}/blockService`, payload, options)
      .map((response: any) => {
        return response;
      });
  }

  /**
   * To get block services available data
   * @param outletId 
   * @param widgetSfId
   * @returns 
   */
  getBlockServices(outletId: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/blockService`, options)
      .map((response: any) => {
        return response;
        // return JSON.parse('{"delivery":[{"fromTime":"01:00:00","toTime":"13:10:00","startDate":"2022-08-17","endDate":"2022-08-17"},{"fromTime":"01:00:00","toTime":"13:10:00","startDate":"2022-09-17","endDate":"2022-09-17"}],"takeAway":[{"fromTime":"01:00:00","toTime":"13:10:00","startDate":"2022-08-17","endDate":"2022-08-17"},{"fromTime":"01:00:00","toTime":"13:10:00","startDate":"2022-09-17","endDate":"2022-09-17"}],"inRoomDining":[{"fromTime":"01:00:00","toTime":"13:10:00","startDate":"2022-09-17","endDate":"2022-09-17"},{"fromTime":"01:00:00","toTime":"13:10:00","startDate":"2022-08-17","endDate":"2022-08-17"}]}');
      });
  }

  /**
   * To publish items
   * @param outletId 
   * @param widgetSfId
   * @returns 
   */
  publishItems(outletId: any, widgetSfId: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    let payload = { pushPublishMenuList: "true" }
    return this.httpWrapper.post(`outlet/${outletId}/PublishMenu`, payload, options)
      .map((response: any) => {
        return response;
      });
  }

  getWidgets(outletId: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/getWidgets`, options)
      .map((response: any) => {
        if (response) {
          return response.map((e: any) => new Widget(e));
        }
      });
  }

  getTags(outletId: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/getDescriptionTags`, options)
      .map((response: any) => {
        if (response) {
          return response;
        }
      });

  }

  getItemNameThroughPOSCode(posCode: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.get(`getItemPosCode/` + posCode, options)
      .map((response: any) => {
        if (response) {
          return response;
        }
      });
  }

  camerafnc() {
    var promise = new Promise((resolve, reject) => {
      const options: CameraOptions = {
        quality: 60,
        destinationType: this.camera.DestinationType.FILE_URI,
        encodingType: this.camera.EncodingType.JPEG,
        sourceType: this.camera.PictureSourceType.CAMERA,
        mediaType: this.camera.MediaType.PICTURE,
        allowEdit: false,
        correctOrientation: true,
        targetHeight: window.screen.availHeight,
        targetWidth: window.screen.availWidth
      };
      this.camera.getPicture(options).then((imageData) => {
        resolve(imageData);
      }, (err) => {
        console.log('Camera err=>' ,err )
        reject(err);
      });
    });
    return promise;
  }

  // /*********************************************** CAMERA FUNCTIONALITY ENDS HERE ***************************************************************/


  // /*********************************************** GALLERY FUNCTIONALITY STARTS HERE *************************************************************/

  galleryfnc() {
    var promise = new Promise((resolve, reject) => {
      const options: CameraOptions = {
        quality: 60,
        destinationType: this.camera.DestinationType.FILE_URI,
        encodingType: this.camera.EncodingType.JPEG,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
        mediaType: this.camera.MediaType.PICTURE,
        allowEdit: false,
        correctOrientation: true
      };
      this.camera.getPicture(options).then((imageData) => {
        resolve(imageData);
      }, (err) => {
        console.log('Camera err=>' ,err )
        reject(err);
      });
    });
    return promise;
  }

  /*********************************************** GALLERY FUNCTIONALITY ENDS HERE ***************************************************************/


  /*********************************************** UPLOAD IMAGE ON SERVER FUNCTIONALITY STARTS HERE ***********************************************/


  uploadFile(imageURI) {
    console.log(":uploadfile with imageuri has been called");
    var promise = new Promise((resolve, reject) => {
      if (this.appData && this.appData.userDetails) {
        this.sessionid = this.appData.userDetails.sessionid;
        this.csrftoken = this.appData.csrftoken.csrftoken
      }
      this.uploadImageFunc(imageURI).then(resp => {
        console.log("resp", resp)
        if (resp) {
          resolve(resp);
        } else {
          console.log("check error ",'reject')
          reject(false);
        }
      }).catch(
        (err) => {
          console.log("check error ", err)
          reject(false);
        }
      );
    })
    return promise;
  }

  upload(fileDetails):Observable<any> {
    const formData = new FormData()
    // fileDetails['base64'] = base;
    console.log("fileDetails", fileDetails)
    // base = fileDetails.base64
    formData.append('fileName',fileDetails);
    // formData.append('fileName',fileDetails,fileDetails.base64);
    
    return this.http.post(`${appApiResources.baseUrl}itemImage`, formData)
    // return this.httpWrapper.post(`itemImage`,file,formData)
    // .map((response: any) => {
    //   return response;
    // });
}



  uploadImageFunc(imageURI) {
    console.log("Media service upload image URI::>", imageURI);

    // this.upload(imageURI,fileDetails);

    return;
    
    let promise = new Promise((resolve, reject) => {
      let options 
       options = {
        fileKey: 'image',
        fileName: imageURI,
        chunkedMode: false,
        mimeType: 'image/jpeg',
        httpMethod: 'POST',
        headers: {
          appid: environment.headers.appid,
          client_id: environment.headers.client_id,
          client_secret: environment.headers.client_secret,
          Country: environment.headers.country,
          program_id: environment.headers.program_id,
          subProgramID: environment.headers.subprogramid,
          uuid: this.cookieService.get('uuid'),
          nationality: environment.headers.nationality,
          Language: environment.headers.language,
          SessionIDToken: this.appData.sessionToken,
          csrftoken: this.appData.csrftoken,
          employeeID: this.appData.userDetails.employeeId
        }
      };
      
      return this.httpWrapper.post(`itemImage`,imageURI,options)
          .map((response: any) => {
            return response;
          });
      this.translateService.get('uploadmessage.uploadImage').subscribe((res: string) => {
        this.loaderText = res;
        this.commonService.presentLoader(this.loaderText);
        
        const fileTransfer: FileTransferObject = this.fileTransfer.create();
        const options: FileUploadOptions = {
          fileKey: 'image',
          fileName: imageURI,
          chunkedMode: false,
          mimeType: 'image/jpeg',
          httpMethod: 'POST',
          headers: {
            appid: environment.headers.appid,
            client_id: environment.headers.client_id,
            client_secret: environment.headers.client_secret,
            Country: environment.headers.country,
            program_id: environment.headers.program_id,
            subProgramID: environment.headers.subprogramid,
            uuid: this.commonService.uuid,
            nationality: environment.headers.nationality,
            Language: environment.headers.language,
            SessionIDToken: this.appData.sessionToken,
            csrftoken: this.appData.csrftoken,
            employeeID: this.appData.userDetails.employeeId
          }
        };
        fileTransfer.upload(imageURI, `${appApiResources.baseUrl}itemImage`, options)
          .then((data) => {
            const response = data.response;
            this.imgUrl = JSON.parse(response);
            if (this.imgUrl) {
              this.commonService.dismissLoader();
              resolve(this.imgUrl);
            } else {
              this.commonService.dismissLoader();
              this.translateService.get('toastMsg.imgUploadFail').subscribe(
                (res) => {
                  this.commonService.presentToast(res)
                }
              );
            }
          }).catch(err => {
            this.commonService.dismissLoader();
            this.translateService.get('toastMsg.imgUploadFail').subscribe(
              (res) => {
                this.commonService.presentToast(res)
              }
            );
            reject(err);
          });
      });
    });
    return promise;
  }

  editMenuGet(outletId: any, widgetSfId: any, comingFor?: any) {
    let options = new HttpInputData();
    let params = {};
    params['getPublishItems'] = true;
    params['isItemRequired'] = true;
    let httpParams = new HttpParams({ fromObject: params });
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/getWidgetDetails/${widgetSfId}`, options)
      .map((response: any) => {
        return response;
      })
  }

  getRevenue(outletId: any) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    // let data = '{"fullMenu":{"days":[0,1,3,4],"startTime":"2:10","endTime":"4:30","startDate":"2022-08-12","endDate":"2022-08-26","percentageSelectedMenu":"10"},"menuWidget":[{"days":[0,1,3,4],"startTime":"2:10","endTime":"4:30","startDate":"2022-08-12","endDate":"2022-08-26","percentageSelectedWidget":"10","widgetId":"235432423","widgetName":"Widget One"},{"days":[0,1,3,4],"startTime":"2:10","endTime":"4:30","startDate":"2022-08-12","endDate":"2022-08-30","percentageSelectedWidget":"20","widgetId":"235433423","widgetName":"Widget Two"}],"byDelivery":{"days":[0,1,3,4],"startTime":"2:10","endTime":"4:30","startDate":"2022-08-12","endDate":"2022-08-26","percentageSelectedDelivery":"30"}}';
    options.params = httpParams;
    return this.httpWrapper.get(`outlet/${outletId}/revenueManagement`, options)
      .map((response: any) => {
        console.log("response of get revenue", JSON.stringify(response) );
        // return new RevenueGet(response);
        return new RevenueGet(response);
      });
  }



  itemAvailablePost(outletId, data) {
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    options.params = httpParams;
    return this.httpWrapper.post(`outlet/${outletId}/itemAvailability`, data, options)
      .map((response: any) => {
        return response;
      })
  }

  updateSequence(outletId, data) {
    console.log("updated sequence api call");
    let options = new HttpInputData();
    return this.httpWrapper.put(`outlet/${outletId}/sequenceUpdate`, data, options)
      .map((response: any) => {
        return response;
      })
  }


  deleteUploadedImage(itemId: any) {
    let options = new HttpInputData();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpWrapper.delete(`itemImage?itemSfid=${itemId}`, httpOptions)
      .map((response: any) => {
        this.commonService.presentToast(response.statusMessage);
        return response;
      });
  }

  unblockDatesAPI(outletId,blockId) {
    console.log("updated sequence api call");
    let options = new HttpInputData();
    return this.httpWrapper.delete(`outlet/${outletId}/blockService/${blockId}`, options)
      .map((response: any) => {
        return response;
      })
  }

  

  MbowaemDataApi(data) {
     console.log("aem data check", data);
    // //console.log(data);
    // //console.log(data['keys'].length);
    let lang = this.localizationService.getDeviceLanguage() || 'en'
    if (data['keys'].length > 0) {
      let checkData = data['keys'][0].identifier.includes(`${environment.Content}/${environment.CountryCode}/${lang}`);
      console.log(checkData);
      if (checkData) {
      } else {
        data['keys'].forEach(element => {
          element.identifier = environment.Content + JAVASCRIPT.FORWARDSLASH + environment.CountryCode + JAVASCRIPT.FORWARDSLASH + lang + element.identifier;
        });
      }
      data['bannerPath'] = data.keys[0].identifier;
      console.log("data for keys", data);
    }
    return this.httpWrapper.postAemData(environment.aem_base_url, data).pipe(map((res: any) => {
      if (this.platform.is('cordova')) {
        if (this.httpWrapper.checkNative == false) {
          return res;
        } else {
          //impotanat commenet not remove when you go test aem api in mobile this section is open 
          var data = JSON.parse(res)
          return data;
        }

      } else {
        // impotant msg when you serve in web view open this return above return data for mobile this for web view
        return res;
      }
    }, err => {
      console.error("AEM error", err);
    }));
  }

    // past order detail api 
    pastorderDetailsApi(outletId, type,bucketNumber?: any,bucketSize?:any) {
      if(bucketSize =='undefined'||bucketSize == undefined ||bucketSize ==null ) {
        bucketSize = 10
      } else {
        bucketSize = 10
      }
      let httpParams = new HttpParams();
      let options = new HttpInputData();
      options.params = httpParams;
      return this.httpWrapper.get(`outlet/${outletId}/orders?type=${type}&bucketSize=${bucketSize}&bucketNumber=${bucketNumber}`, options)
        .map((response: any) => {
          return response;
        });
    }




}
