

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { EncryptionService } from '../encryption/encryption.service';
import { Platform } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
/**
 * Storage Utility class.
 */
export class StorageService {
  setCsrfToken: any;
  authenticatekey(authenticatekey: any, arg1: string) {
    throw new Error('Method not implemented.');
  }
  checkUserLoged: boolean
  constructor(private storage: Storage,
    public encryptionServ: EncryptionService,
    public platform: Platform) 
    {
      this.init();
     }

  /**
    * Set the value for the given key.
    * @param key - key
    * @param value - value
    */

   async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this.storage = storage;
  }

  public async set(key: string, value: any, type: string) {

    if (this.platform.is('cordova')) {
      if (type == 'anonymousKey') {
        return new Promise((resolve, reject) => {
          this.encryptionServ.encryptFunc(value, type).then((res) => {
            if (res) {
              this.storage.set(`setting:${key}`, res)
              //console.log('SUCCESSFUL  encryptFunc anonymousKey', res)
              resolve(res);
            }
            else {
              reject(false);
            }
          }, err => {
            ////console.log('EncryptionErr ==>', err);
            return (false);
          });
        })
      } else if (type == 'authenticatekey') {
        ////console.log("hittt==>>authenticatekey ")
        return new Promise((resolve, reject) => {
          this.encryptionServ.encryptFunc(value, type).then((res) => {
            if (res) {
              this.storage.set(`setting:${key}`, res)
              ////console.log('SUCCESSFUL  encryptFunc authenticatekey', res)
              resolve(res);
            }
            else {
              reject(false);
            }
          }, err => {
            ////console.log('EncryptionErr ==>', err);
            return (false);
          });
        })
      }
    }
    else {
      ////console.log("webbbHIT")
      return this.storage.set(`setting:${key}`, value);
    }
  }


  /**
    * Gets the value of given key.
    * @param key key
    */
  public async get(key: string, dataType: string) {

    if (this.platform.is('cordova')) {
      if (dataType == 'anonymousKey') {
        return new Promise((resolve, reject) => {
          ////console.log("init key===>>>>", key)
          this.storage.get(`setting:${key}`).then(res => {
            // //console.log("hittt decryptFunc ==>>anonymousKey ")
            if (res) {
              this.encryptionServ.decryptFunc(res, dataType).then((resp) => {
                if (resp) {
                  var result = JSON.stringify(resp);
                  //console.log('SUCCESSFUL decryptFunc anonymousKey')
                  resolve(result);
                } else {
                  reject(false);
                }
              });
            } else {
              reject(false);
            }
          }).catch(err => {
            ////console.log('GetStorageDataErr ==>', err);
          })
        })
      }
      else if (this.checkUserLoged == true) {
        if (dataType == 'authenticatekey') {
          return new Promise((resolve, reject) => {
            this.storage.get(`setting:${key}`).then(res => {
              //console.log("hittt decryptFunc ==>>authenticatekey ")
              if (res) {
                this.encryptionServ.decryptFunc(res, dataType).then((resp) => {
                  if (resp) {
                    var result = JSON.stringify(resp);
                    //console.log('SUCCESSFUL  decryptFunc authenticatekey')
                    resolve(result);
                  } else {
                    reject(false);
                  }
                });
              } else {
                reject(false);
              }
            }).catch(err => {
              ////console.log('GetStorageDataErr ==>', err);
            })
          })
        }
      }

    } else {
      ////console.log("webbbHIT")
      return await this.storage.get(`setting:${key}`);

    }
  }

  /**
   * Removes the data for associated key.
   * @param key 
   */
  public async remove(key: String) {
    return await this.storage.remove(`setting:${key}`);
  }

  /**
   * Clears the storage.
   */
  public clear() {
    this.storage.clear().then(() => {
    });
  }



  public async getData(key: string) {
    return await localStorage.getItem(key);
  }
  public async setData(key: string, value: any) {
    return await localStorage.setItem(key, value);
  }

  public async storageClear() {
    return await localStorage.clear();
  }

  public async storageremoveItem(key: string) {
    return await localStorage.removeItem(key)
  }

}
