import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { IonInfiniteScroll } from '@ionic/angular';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { ProfileService } from 'src/app/services/profile.service';
import { COUNT, TITLE, AWAITING_ARRIVAL_PAGE, JAVASCRIPT, CUSTOM_SELECT_ICON } from 'src/app/app.constants';
import { EventService } from 'src/app/services/event/event.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
@Component({
  selector: 'app-awaiting-arival',
  templateUrl: './awaiting-arival.component.html',
  styleUrls: ['./awaiting-arival.component.scss'],
})
export class AwaitingArivalComponent implements OnInit {

  @ViewChild(IonInfiniteScroll, { static: true }) infiniteScroll: IonInfiniteScroll
  isExpend = false;
  myAwaitArrivalData: any = [];
  reservationTimeArr: any = [];
  checkValue: any;
  checkNo: any;
  getReservationType: string;
  reservationArrival: any;
  getReservationArrival: any;
  awaitData: any;
  scroller: false;
  startDate: any;
  endDate: any;
  tableNo: any;
  reservId: any;
  skelton: boolean = true;
  ionicStorageEmployeeId: any;
  userDetailsModal: any;
  reservIdInModal: any;
  empID: string;
  indexValue: number = Number(COUNT.COUNT_1);
  dataset: any;
  guestNameObj: any;
  guestNameArry: any = [];
  tabledetails: string;
  currentDate: number;
  newArry: any = [];
  diffDate: string;
  fourHourDate: string;
  skipValue: boolean;
  enableManageScreen: boolean;
  sendingSfid: any;
  NolistMessageArrival: boolean = false;
 
  popoverOptions = {
    showBackdrop: false,
    dismissOnSelect: true,
    backdropDismiss: true,
    keepContentsMounted: true,
    side: "bottom",
    alignment: "start",
    mode: "md",
    size: "cover",
  };
  reservationID: any;
  allVaccanTable: any[];
  constructor(public plt: Platform,
    public events: EventService,
    public router: Router,
    public common: CommonService,
    public dashboardService: DashboardhomeService,
    public app: AppStateService,
    private translate: TranslateService,
    public profileService: ProfileService,
    public menu: MenuController,
  ) {


    this.awaitData = [];
    for (let i = 0; i <= this.awaitData; i++) {
      this.awaitData.push(this.awaitData.length);
    }
  }

  ionViewWillEnter() {
    this.menu.enable(false)
    this.plt.ready().then((readySource) => {
      this.translate.get(AWAITING_ARRIVAL_PAGE.AWAITING_ARRIVAL).subscribe((res: string) => {
        this.events.publish(TITLE.TITLE, res);
      });
    });
    this.ionicStorageEmployeeId = this.app.getAppData();
    this.skelton = true;
    this.reservationTimeArr = [];
    this.ionViewDidEnter();
    this.getReservationsArrivalApi();
    this.allTableList()
  }

  backDashboard() {
    this.router.navigate(['web-dashboard']);
  }

  ngOnInit() {
    // this.notifier.subscribe(data => {
    //   AwaitingArrivalPage
    // });
  

    // this.diffDate = new Date(new Date().getTime() + 14400000).toLocaleTimeString([], { hour: AWAITING_ARRIVAL_PAGE.digit, minute: AWAITING_ARRIVAL_PAGE.digit })
    this.currentDate = new Date().getTime();
    this.ionicStorageEmployeeId = this.app.getAppData();
    this.empID = localStorage.getItem(AWAITING_ARRIVAL_PAGE.employeeId)
    this.getReservationArrival = this.dashboardService.reservationsTypeData;

    // this.endDate = new Date().toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
    // this.startDate = new Date(Date.now()).toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
    this.endDate =  moment().format('YYYY-MM-DD')
    this.startDate =  moment().format('YYYY-MM-DD')
    this.ionViewWillEnter()

  }

  ionViewDidEnter() {
    this.translate.get(AWAITING_ARRIVAL_PAGE.AWAITING_ARRIVAL).subscribe((res: string) => {
      this.events.publish(TITLE.TITLE, res);
    });
    const ionSelects = document.querySelectorAll(CUSTOM_SELECT_ICON.ionSelect);
    ionSelects.forEach((sel) => {
      sel.shadowRoot.querySelectorAll(CUSTOM_SELECT_ICON.selectIconInner).forEach((elem) => {
        elem.setAttribute(JAVASCRIPT.STYLE, JAVASCRIPT.DISPLAY_NONE);
      });
    });
  }

  profile(memberShipNo) {
    this.profileService.membershipNumber = memberShipNo
    if (this.profileService.membershipNumber) {
      this.router.navigate(['guest-profile']);
    }
  }

  openModal(data, userRoleType) {
    //console.log(data);
    //console.log(userRoleType);
    this.reservationID = data.reservationID
    if (userRoleType == AWAITING_ARRIVAL_PAGE.Pool || userRoleType == AWAITING_ARRIVAL_PAGE.Spa) {
      this.reservIdInModal = data.reservationID
      this.userDetailsModal = data
      document.getElementById(AWAITING_ARRIVAL_PAGE.arrivalArrived).classList.add(JAVASCRIPT.SHOW);
    }
    else {
      document.getElementById(AWAITING_ARRIVAL_PAGE.customModal).classList.add(JAVASCRIPT.SHOW);
      this.userDetailsModal = data
    }
  }

  closeModal() {
    this.checkValue = JAVASCRIPT.BLANK;
    this.skipValue = false;
    this.tableNo = JAVASCRIPT.BLANK;
    this.checkNo = JAVASCRIPT.BLANK;
    document.getElementById(AWAITING_ARRIVAL_PAGE.customModal).classList.remove(JAVASCRIPT.SHOW);
  }

  confirmModal(reservationID) {
    this.reservationID = reservationID
    document.getElementById(AWAITING_ARRIVAL_PAGE.customModal).classList.remove(JAVASCRIPT.SHOW);
    this.alotReservationTableApi();
  }

  ArrivalcloseModal() {
    this.skipValue = false;
    this.checkValue = JAVASCRIPT.BLANK;
    document.getElementById(AWAITING_ARRIVAL_PAGE.arrivalArrived).classList.remove(JAVASCRIPT.SHOW);
  }

  ArrivalGuestNamecloseModal() {
    this.checkValue = JAVASCRIPT.BLANK
    this.skipValue = false;
    this.guestNameArry = [];
    this.guestNameObj = JAVASCRIPT.BLANK
    this.checkNo = JAVASCRIPT.BLANK
    document.getElementById(AWAITING_ARRIVAL_PAGE.arrivalArrivedGuestName).classList.remove(JAVASCRIPT.SHOW);
    document.getElementById(AWAITING_ARRIVAL_PAGE.arrivalArrived).classList.remove(JAVASCRIPT.SHOW);

  }

  manageReservation(reservationId) {
    this.sendingSfid = reservationId
    this.enableManageScreen = true

    // console.log("idd",setSfid)
    // const queryParams = { id: setSfid }
    // this.router.navigate(['manage-reservation'], {
    //   queryParams: queryParams
    // });
  }

  continueArrival() {
    document.getElementById(AWAITING_ARRIVAL_PAGE.arrivalArrived).classList.remove(JAVASCRIPT.SHOW);
    document.getElementById(AWAITING_ARRIVAL_PAGE.arrivalArrivedGuestName).classList.add(JAVASCRIPT.SHOW);
  }

  skipArrival() {
    if (!this.checkNo) {
      //console.log("hit khali")
      this.skipValue = true;
      document.getElementById(AWAITING_ARRIVAL_PAGE.arrivalArrivedGuestName).classList.add(JAVASCRIPT.SHOW);
    } else {
      // alert("hit")
      this.alotReservationTableApi()
    }
  }
  skpiConfirmApi() {
    this.alotReservationTableApi()

  }

  saveGuestName(reservIdInModal) {
    this.guestNameArry.push(this.guestNameObj)
    this.guestNameObj = JAVASCRIPT.BLANK
    if ((this.guestNameArry.length + 1) == (this.userDetailsModal.guestCount) || this.userDetailsModal.guestCount == 1) {
      document.getElementById(AWAITING_ARRIVAL_PAGE.arrivalArrivedGuestName).classList.remove(JAVASCRIPT.SHOW);
      this.alotReservationTableApi();
    }
  }

  getReservationsArrivalApi() {

    this.dashboardService.allReservationApi(this.getReservationArrival, this.startDate, this.endDate, JAVASCRIPT.BLANK, JAVASCRIPT.BLANK, this.indexValue, JAVASCRIPT.BLANK).subscribe(res => {
      // this.dashboardService.allReservationApi(this.getReservationArrival, '2020-06-23', '2020-06-23', JAVASCRIPT.BLANK, JAVASCRIPT.BLANK, this.indexValue, JAVASCRIPT.BLANK).subscribe(res => {

      this.skelton = false;
      if (res) {
        this.dataset = res.isNextDataSet;
        this.awaitData = res.result;
        if (this.awaitData.length === 0) {
          this.NolistMessageArrival = true;
        }
        this.scroller = res.isNextDataSet;
        for (let i = 0; i < this.awaitData.length; i++) {
          for (let j = 0; j < this.awaitData[i].reservationDetails.length; j++) {
            this.reservId = this.awaitData[i].reservationDetails[j].reservationID
            if (!this.reservationTimeArr.includes(this.awaitData[i].reservationDetails[j].reservationTime))
              this.reservationTimeArr.push(this.awaitData[i].reservationDetails[j].reservationTime);
          }
        }
      }
    }, err => {
      this.common.errorHandler(err);
    })
  }
  //..Api of the confirmation of the table in the AwaitArrival Screen..//
  alotReservationTableApi() {
    this.common.presentLoader();
    if(this.ionicStorageEmployeeId.userDetails.outletType=='Restaurant') {
      this.dashboardService.assignTable(this.tableNo,this.userDetailsModal,this.ionicStorageEmployeeId.userDetails.sfid,this.checkNo,this.guestNameArry).subscribe(res => {
        if (res) {
          this.common.dismissLoader();
          this.guestNameObj = JAVASCRIPT.BLANK
          this.guestNameArry = []
          this.skipValue = false
          localStorage.setItem('RestaurantView',"true");
          window.location.reload()
        } else {
          this.tableNo = ""
          this.tabledetails = JAVASCRIPT.BLANK
          this.guestNameObj = JAVASCRIPT.BLANK
          this.guestNameArry = []
          this.checkValue = JAVASCRIPT.BLANK
          this.checkNo = JAVASCRIPT.BLANK
          this.common.dismissLoader();
        }
      }, err => {
        this.tableNo = ""
        this.tabledetails = JAVASCRIPT.BLANK
        this.guestNameObj = JAVASCRIPT.BLANK
        this.guestNameArry = []
        this.checkValue = JAVASCRIPT.BLANK
        this.checkNo = JAVASCRIPT.BLANK
        this.common.dismissLoader();
        this.common.errorHandler(err)
      }
    )
   
    }
    else if(this.ionicStorageEmployeeId.userDetails.outletType!='Restaurant') {
      this.dashboardService.allotReservartionApi(this.reservationID, this.tabledetails || this.tableNo,
        this.ionicStorageEmployeeId.userDetails.employeeId, this.guestNameArry, this.ionicStorageEmployeeId.userDetails.outletType, this.checkNo).subscribe(res => {
          if (res) {
            this.common.dismissLoader();
            this.guestNameObj = JAVASCRIPT.BLANK
            this.guestNameArry = []
            this.skipValue = false
            window.location.reload()
          } else {
            this.tableNo = ""
            this.tabledetails = JAVASCRIPT.BLANK
            this.guestNameObj = JAVASCRIPT.BLANK
            this.guestNameArry = []
            this.checkValue = JAVASCRIPT.BLANK
            this.checkNo = JAVASCRIPT.BLANK
            this.common.dismissLoader();
          }
        }, err => {
          this.tableNo = ""
          this.tabledetails = JAVASCRIPT.BLANK
          this.guestNameObj = JAVASCRIPT.BLANK
          this.guestNameArry = []
          this.checkValue = JAVASCRIPT.BLANK
          this.checkNo = JAVASCRIPT.BLANK
          this.common.dismissLoader();
          this.common.errorHandler(err)
        }
      )
    }
    
          
  }

  //..Infinite scroll loader rotation..//
  loadData(event) {
    this.indexValue;
    this.getReservationsArrivalApi();
    setTimeout(() => {
      if (this.dataset != JAVASCRIPT.FALSE || this.dataset != JAVASCRIPT.BLANK) {
        event.target.disabled = true;
      } else {
        this.getReservationsArrivalApi();
        this.indexValue++;
      }
      for (let i = 0; i <= this.awaitData; i++) {
        this.awaitData.push(this.awaitData.length);
      }
      event.target.complete();
      if (this.scroller == this.scroller) {
        event.target.disabled = true;
      }
      this.common.dismissLoader();
    }, 2000)
  }

  // expanding of the privacy policy block
  expand() {
    this.isExpend = !this.isExpend
    const ex = document.getElementById(AWAITING_ARRIVAL_PAGE.mainCard);
    if (this.isExpend === true) {
      ex.style.height = AWAITING_ARRIVAL_PAGE.CSS_200px;
      ex.style.transition = AWAITING_ARRIVAL_PAGE.CSS_linear;
    } else {
      ex.style.height = AWAITING_ARRIVAL_PAGE.CSS_75px;
      ex.style.transition = AWAITING_ARRIVAL_PAGE.CSS_linear;
    }
  }

  backAwaiting() {
    this.getReservationsArrivalApi();
    this.enableManageScreen = false
  }

  allTableList() {
    this.dashboardService.tableAllotmentTypeApi(this.ionicStorageEmployeeId.userDetails.sfid, this.ionicStorageEmployeeId.userDetails.outletId, 'All').subscribe(res => {
      this.allVaccanTable = []
      if (res) {
        res.data.forEach(element => {
          if (element.available == true) {
            this.allVaccanTable.push(element)

          }
        });
      
      }
    })
  }

  posCheckIn(reservationId) {
     this.common.presentLoader()
      this.dashboardService.posAPI(reservationId).subscribe(res => {
        if(res) {
          this.common.popup(res.Message)
          this.common.dismissLoader()
        }
      },err=> {
        this.common.dismissLoader()
        this.common.errorHandler(err)
      })
  }

  
}
