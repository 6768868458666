import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import {
  JAVASCRIPT,
  CUSTOM_SELECT_ICON,
  TITLE,
  OPEN_RESERVATION_PAGE,
  SPENDERS_PAGE,
  COUNT_NUMBER,
  REGEX,
  IdentifierExporterPayload,
  ReservationPromocodeQueryString,

  ACTIVATE_RESERVATION_PAGE
} from 'src/app/app.constants';
import { ModalController, Platform, MenuController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/services/event/event.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { OpenReservationService } from 'src/app/services/open-reservation/open-reservation.service';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';
import { LocalizationService } from 'src/app/services/core/localize/localization.service';
import { SelectMembershipComponent } from 'src/app/modals/select-membership/select-membership.component';
import { StorageService } from 'src/app/services/core/storage/storage.service';
import * as phoneValidation from "libphonenumber-js";


declare var $: any;
@Component({
  selector: 'app-makereservation',
  templateUrl: './makereservation.component.html',
  styleUrls: ['./makereservation.component.scss'],
})
export class MakereservationComponent implements OnInit {
  salutations: Array<any>;
  salutationInterface = {
    header: '',
    translucent: true
  };
  countries: Array<any>;
  countryCodeInterface = {
    header: '',
    translucent: true
  };
  membershipDetailForm: FormGroup;
  mobileVerified: boolean = false;
  emailVerified: boolean = false;
  memberProfileData: any;
  cardBenefits: Array<any>;
  certificateBenefits: Array<any>;
  outletsList: Array<any>;
  membershipNumber: any;
  memberProfileOnOutletChange: boolean;
  promocodeDetails: any;
  expandBlock: 'email' | 'mobile' | 'name' | '' = 'mobile';
  benefitExpanded: boolean;
  benefitsTypeSelected: boolean;
  disalbedNameCard: boolean;
  errorCntrl: any;
  appData: any;
  appstateData: any;
  sendDetailsmember: String;
  enablecreateReservation: boolean ;
  mobileChange: any;
  showMakeReservation: boolean = false
  propertyId: any;
  getoutletid: any;
  roomNumbercheck : boolean = true
  namefield: boolean = true;
  roomcheck: boolean = true;
  showMobileError: boolean = false
  loginOTPDuration: any;
  loginOTPTimeOut: any;
  sendType: any;
  emailChek: boolean;
  isValidMobNumber: boolean = false;
  checkByMobile: boolean = false
  constructor(
    public appState: AppStateService,
    public platform: Platform,
    private router: Router,
    public common: CommonService,
    public plt: Platform,
    private translate: TranslateService,
    public events: EventService,
    private modalController: ModalController,
    public utilities: UtilitiesService,
    private onBoardingService: OnboardingService,
    private openReservationService: OpenReservationService,
    private profileService: ProfileService,
    private localizationService: LocalizationService,
    public menu: MenuController,
    public storage: StorageService,
    public alertCtrl: AlertController

  ) {
    this.membershipDetailForm = new FormGroup({
      countryCodeSelected: new FormControl(''),
      mobile: new FormControl(''),
      email: new FormControl('', Validators.compose([ Validators.pattern(REGEX.email)])),
      salutation: new FormControl(''),
      firstName: new FormControl('', Validators.compose([Validators.required])),
      lastName: new FormControl('', Validators.compose([Validators.required])),
      membershipNumber: new FormControl(''),
      benefitType: new FormControl(''),
      benefit: new FormControl('certificate'),
      selectedOutlet: new FormControl(''),
      promocode: new FormControl(''),
      memberBenefit: new FormControl(''),
      promocodeDetails: new FormControl(''),
      memberId: new FormControl(''),
      roomNumber: new FormControl('')
    });
    this.membershipDetailForm.valueChanges.subscribe(values => {
    
      if(this.membershipDetailForm.status ==  'VALID' && this.membershipDetailForm.get('firstName').valid && this.membershipDetailForm.get('lastName').valid
      && this.membershipDetailForm.get('email').value == ''  && this.membershipDetailForm.get('mobile').value == '' && this.membershipDetailForm.get('roomNumber').value == ''){
        this.namefield = true;
      }else {
        this.namefield = false;
      }
      
    })
    this.promocode.valueChanges.subscribe((value: any) => {
      try {
        this.promocode.setValue(value.toUpperCase(), { emitEvent: false });
      } catch (e: any) {
        console.log("Value e::>", e);
      }
    });

    
   

  }
  get promocode(): any { return this.membershipDetailForm.get(ACTIVATE_RESERVATION_PAGE.promocode) as FormControl }



  checker(values){
    console.log("checker called", values);
    this.roomNumbercheck = true;
    
    if(values.roomNumber != "" && values.firstName != "" && values.lastName != ""){
      console.log("i am called for condition");
      this.disalbedNameCard = false;
      this.roomNumbercheck = false;
    }
    else {
      // this.disalbedNameCard = true;
      // this.roomNumbercheck = true;
    }
  }


  ngOnInit() {
    const appDataa = this.appState.getAppData();
    this.getoutletid = appDataa
    console.log("ng onit call")
    this.loginOTPDuration = appDataa.appInitData.loginOTPDuration;
    this.membershipDetailForm.patchValue({
      selectedOutlet: this.getoutletid.userDetails.outletId
    })
    // this.membershipDetailForm.setValidators([this.utilitiesService.validatePhoneNumber]);

    this.membershipDetailForm.controls['selectedOutlet'].valueChanges.subscribe((outletID) => {
      if (outletID) {
        // this.openReservationService.outletID = outletID;
        this.common.outletIDD = outletID;
        this.membershipDetailForm.patchValue({
          benefitType: 'promocode',
          promocode: '',
          memberBenefit: '',
          promocodeDetails: ''
        })
        if (this.memberProfileOnOutletChange && this.memberProfileData && this.membershipNumber) {
          this.getMemberProfileData(this.membershipNumber);
        }
      }
    });

  }

  onInput(type: 'email' | 'mobile', event: any) {
    console.log(" type ==>  ", type)
    switch (type) {
      case 'email':
        this.emailChek = this.membershipDetailForm.controls['email'].valid
          if(this.membershipDetailForm.value.mobile!=''&& this.membershipDetailForm.value.email!='' && this.checkByMobile) {
            this.membershipDetailForm.patchValue({
              'firstName': '',
              'lastName': '',
              'mobile': '',
            });
            this.certificateBenefits = []
            this.cardBenefits = []
          }
          if(this.membershipDetailForm.value.mobile =='' && this.membershipDetailForm.value.email !='' ) {
            console.log("1")
            if(this.emailChek) {
              this.sendType = 'email'
              this.getProfileDetails()
            }
                  
          }
          
        
          
        
          // // this.emailVerified = true;
          // this.expandBlock = type
          // if (!this.membershipDetailForm.value.selectedOutlet) {
          //   this.membershipDetailForm.patchValue({
          //     'selectedOutlet': this.appstateData.outletId
          //   });
          // }

          // this.onBoardingService.activateApiMemberData = {
          //   email: this.membershipDetailForm.value.email
          // }
         else {
          this.emailVerified = false;
        }
        break;

      case 'mobile':
        this.checkByMobile = false
        const pattern = REGEX.onlyNumberValidate;
        let curStr = event.target.value;
        let updateMob = curStr.replace(pattern, '');
        this.membershipDetailForm.get('mobile').setValue(updateMob)
        this.disalbedNameCard = false;
        this.validatePhoneNumber()
        this.membershipDetailForm.patchValue({
          salutation: this.salutations[0],
          email: '',
          firstName: '',
          lastName: '',
          membershipNumber: '',
          // selectedOutlet: '',
          promocode: '',
          benefitType: '',
          benefit: '',
          memberBenefit: '',
          promocodeDetails: ''
        })
        // this.mobileVerified = false;
        this.emailVerified = false;
        this.memberProfileData = null;
        this.cardBenefits = [];
        this.certificateBenefits = [];
        this.membershipNumber = '';
        this.memberProfileOnOutletChange = false;
        this.benefitExpanded = false;

        break;
    }
  }

  validatePhoneNumber() {
   
    console.log("this.membershipDetailForm.value", this.membershipDetailForm)
    let isValidNumber;
    let stringNumber = this.membershipDetailForm.value.mobile ? this.membershipDetailForm.value.mobile.toString() : JAVASCRIPT.BLANKDOUBLE;
    let upperCaseCountryCode = this.membershipDetailForm.value.countryCodeSelected ? this.membershipDetailForm.value.countryCodeSelected.iso2.toUpperCase() : JAVASCRIPT.BLANKDOUBLE;
    let parsedNumber: any = phoneValidation.parseNumber(stringNumber, upperCaseCountryCode);
    isValidNumber = phoneValidation.isValidNumber(parsedNumber);
    this.isValidMobNumber = isValidNumber



    clearTimeout(this.loginOTPTimeOut);
    if (isValidNumber) {
      if(this.membershipDetailForm.value.countryCodeSelected.dialCode in this.loginOTPDuration && isValidNumber) {
        this.loginOTPTimeOut =   setTimeout(() => {
          this.getProfileDetails()
        }, this.loginOTPDuration[this.membershipDetailForm.value.countryCodeSelected.dialCode]?.delayDuration * 1000);
      } else {
        if (isValidNumber) {
          this.sendType = 'mobile'
          this.getProfileDetails()
            return null;
          }
      }
    } else {
      console.log("invalid", isValidNumber)
      this.showMobileError = true

      this.expandBlock = 'mobile';
      this.mobileVerified = false;
      this.certificateBenefits = []
      this.cardBenefits = []
      return { isInvalidNumber: true };
    }
  }

  getcountryCode() {
    this.disalbedNameCard = false;
    this.membershipDetailForm.patchValue({
      email: '',
      firstName: '',
      lastName: '',
      membershipNumber: '',
      // selectedOutlet: '',
      promocode: '',
      benefitType: '',
      benefit: '',
      memberBenefit: '',
      promocodeDetails: ''
    })
    this.emailVerified = false;
        this.memberProfileData = null;
        this.cardBenefits = [];
        this.certificateBenefits = [];
        this.membershipNumber = '';
        this.memberProfileOnOutletChange = false;
        this.benefitExpanded = false;
    this.validatePhoneNumber()
  }


  // Custom Select Icon
  ngAfterContentInit() {
    console.log("ngAfterContentInit")
    this.menu.enable(false);
    this.hideIonSelectIcon();
    this.loadCustomInterfaces();
    this.onPageLoad();

  }

  hideIonSelectIcon() {
    const ionSelects = document.querySelectorAll(CUSTOM_SELECT_ICON.ionSelect);
    ionSelects.forEach((sel) => {
      sel.shadowRoot.querySelectorAll(CUSTOM_SELECT_ICON.selectIconInner)
        .forEach((elem) => {
          elem.setAttribute(JAVASCRIPT.STYLE, JAVASCRIPT.DISPLAY_NONE);
        });
    });
    this.plt.ready().then((readySource) => {
      this.translate.get(OPEN_RESERVATION_PAGE.OPEN_RESERVATION).subscribe((res: string) => {
        this.events.publish(TITLE.TITLE, res);
      });
    });
  }

  loadCustomInterfaces() {
    this.translate.get('openReservation.salutation').subscribe((salutation) => {
      this.salutationInterface.header = salutation;
    });
    this.translate.get('Components.SelectCountryText').subscribe((selecteCountryCode) => {
      this.countryCodeInterface.header = selecteCountryCode;
    })
  }

  async onPageLoad() {
    const appData = this.appState.getAppData();
    console.log("appData", appData)
    this.showMakeReservation = appData.appInitData.showMakeReservationOtp
    this.appstateData = appData.userDetails


    if (appData && appData.appInitData && appData.appInitData.honorifics) {
      this.salutations = appData.appInitData.honorifics
    }

    if (appData && appData.appInitData && appData.appInitData.countrysCode) {
      this.countries = this.common.getCurrentCountryOnTop(JSON.stringify(appData.appInitData.countrysCode));
    }

    this.membershipDetailForm.patchValue({
      countryCodeSelected: this.countries[0],
      salutation: this.salutations[0]
    });

    this.getOutletList();

  }

  getProfileDetails() {
  
    
    if (this.membershipDetailForm.value.countryCodeSelected.dialCode && this.membershipDetailForm.value.mobile) {
      var mobile = this.membershipDetailForm.value.countryCodeSelected.dialCode + this.membershipDetailForm.value.mobile;
    }
    if (this.membershipDetailForm.value.email) {
      var email = this.membershipDetailForm.value.email;
    }
    if(this.sendType =='email') {
      var valueSend = email
  } else {
    var valueSend = mobile
  }
  console.log("valueSend", valueSend)
  console.log("sendType", this.sendType)


    this.mobileVerified = true;
    this.changeFocus('name');
    this.common.presentLoader('openReservation.verifyMobile');
    this.openReservationService.verifyMobileAndEmailMake(valueSend,this.sendType).then(
      (res) => {
        console.log("openRess=>>", res)
        this.common.dismissLoader();
        if (res && res.memberId) {
          // this.disalbedNameCard = true
          // this.emailVerified = true;
          var aftersplit = res.countrycode.split('+')
          var resultMob = res.mobile.slice(aftersplit[1].length, res.mobile.length);
          console.log("resultMob", resultMob)
          this.membershipDetailForm.patchValue({
            'salutation': res.Salutation,
            'email': res.email,
            'firstName': res.FirstName,
            'lastName': res.LastName
          });
          this.checkByMobile = true
          if(this.sendType == 'mobile') {
            this.disalbedNameCard = true
            this.expandBlock =  'email'; 
          }
          if(this.sendType == 'email') {
            this.disalbedNameCard = true
            this.expandBlock = 'email';
            this.membershipDetailForm.get('mobile').setValue(resultMob)
            
          }
           
          
          this.membershipDetailForm.patchValue({ "memberId": res.memberId })
          // this.getMemberMemberships(res.memberId);
          let customerSetIdentifier = [];
          res.aemIdentifier.forEach(element => {
            element.customerSets.forEach(ele => {
              if (!ele.customerSetIdentifier.includes(environment.Content)) {
                ele.customerSetIdentifier = environment.Content + JAVASCRIPT.FORWARDSLASH + environment.CountryCode + JAVASCRIPT.FORWARDSLASH + this.localizationService.getDeviceLanguage() + ele.customerSetIdentifier;
              }
              customerSetIdentifier.push(ele.customerSetIdentifier)
            })
          });
         
          if (res.aemIdentifier.length) {
            this.getMembershipCardImages(res, customerSetIdentifier);
          } else {
            this.emailVerified = true;
            this.bindDataForNoMemberExist();
            this.membershipDetailForm.patchValue({
              benefitType: 'promocode',
              benefit: ''
            });
          }



        }
        else {
          this.bindDataForNoMemberExist();
          this.membershipDetailForm.patchValue({ "memberId": "" })
          this.emailVerified = false;
          this.memberProfileData = null;
          // this.expandBlock = 'name';
          this.cardBenefits = [];
          this.certificateBenefits = [];
          this.membershipDetailForm.patchValue({
            benefitType: ''
          });
        }
      }
    ).catch((error) => {
      this.common.dismissLoader();
      if(this.sendType == 'email') {
        this.expandBlock = 'email';
        this.membershipDetailForm.patchValue({
          'firstName': '',
          'lastName': '',
          'mobile':''
        });
      }
       
      if (this.platform.is('cordova')) {
        this.errorCntrl = JSON.parse(error.error);
        if (this.errorCntrl.errorCode == 'TLC-973') {
          //console.log("Openerror", this.errorCntrl)
          this.membershipDetailForm.patchValue({ "memberId": "" })
          this.disalbedNameCard = false
          return
        } else {
          this.common.errorHandler(error);
        }
      } else {
        if (error.error.errorCode == 'TLC-973') {
          //console.log("Openerror", error)
          this.membershipDetailForm.patchValue({ "memberId": "" })
          this.disalbedNameCard = false
          return;
        } else {
          this.common.errorHandler(error);
        }
      }

    })
    // } else {
    // this.emailVerified = true;
    // this.expandBlock = '';
    // this.bindDataForNoMemberExist();
    // this.membershipDetailForm.patchValue({
    // benefitType: 'promocode',
    // benefit: ''
    // });
    // }
  }

  checkOtpVerified(event) {
    console.log("event ki value==>", event)
    if (event && event.statusCode && event.otpType == 'mobile' || event && event.statusCode && event.otpType == 'email') {
      
      if (this.membershipDetailForm.value.countryCodeSelected.dialCode && this.membershipDetailForm.value.mobile) {
        var mobile = this.membershipDetailForm.value.countryCodeSelected.dialCode + this.membershipDetailForm.value.mobile;
      }
      if (this.membershipDetailForm.value.email) {
        var email = this.membershipDetailForm.value.email;
      }
      if(this.sendType =='email') {
        var valueSend = email
    } else {
      var valueSend = mobile
    }
      this.changeFocus('name');
     
      this.common.presentLoader('openReservation.verifyMobile');
      this.openReservationService.verifyMobile(this.sendType,valueSend).then(
        (res) => {
          var aftersplit = res.countrycode.split('+')
          var resultMob = res.mobile.slice(aftersplit[1].length, res.mobile.length);
          console.log("openRess=>>", res)
          this.common.dismissLoader();
          if (res && res.memberId) {
            this.disalbedNameCard = true
             this.membershipDetailForm.patchValue({
              'salutation': res.Salutation,
              'email': res.email,
              'firstName': res.FirstName,
              'lastName': res.LastName,
             
            });
            this.emailVerified = true;
            this.mobileVerified = true;
            if(this.sendType == 'mobile') {
              this.disalbedNameCard = true
              this.expandBlock =  'email'; 
            }
            if(this.sendType == 'email') {
              this.disalbedNameCard = true
              this.expandBlock = 'email';
              this.membershipDetailForm.get('mobile').setValue(resultMob)
              
            }
             
           
           
            this.membershipDetailForm.patchValue({ "memberId": res.memberId })
            // this.getMemberMemberships(res.memberId);
            let customerSetIdentifier = [];
            res.aemIdentifier.forEach(element => {
              element.customerSets.forEach(ele => {
                if (!ele.customerSetIdentifier.includes(environment.Content)) {
                  ele.customerSetIdentifier = environment.Content + JAVASCRIPT.FORWARDSLASH + environment.CountryCode + JAVASCRIPT.FORWARDSLASH + this.localizationService.getDeviceLanguage() + ele.customerSetIdentifier;
                }
                customerSetIdentifier.push(ele.customerSetIdentifier)
              })
            });
            if (res.aemIdentifier.length) {
              this.getMembershipCardImages(res, customerSetIdentifier);
              this.expandBlock = '';
            } else {
              this.emailVerified = true;
              this.expandBlock = '';
              this.bindDataForNoMemberExist();
              this.membershipDetailForm.patchValue({
                benefitType: 'promocode',
                benefit: ''
              });
            }



          }
          else {
            this.bindDataForNoMemberExist();
            this.membershipDetailForm.patchValue({ "memberId": "" })
            this.emailVerified = false;
            this.memberProfileData = null;
            this.expandBlock = 'name';
            this.cardBenefits = [];
            this.certificateBenefits = [];
            this.membershipDetailForm.patchValue({
              benefitType: 'promocode'
            });
          }
        }
      ).catch((error) => {
        this.common.dismissLoader();
        if(this.sendType == 'email') {
          this.expandBlock = 'email';
          this.membershipDetailForm.patchValue({
            'firstName': '',
            'lastName': '',
            'mobile':''
          });
        }
        if (this.platform.is('cordova')) {
          this.errorCntrl = JSON.parse(error.error);
          if (this.errorCntrl.errorCode == 'TLC-973') {
            //console.log("Openerror", this.errorCntrl)
            
            this.membershipDetailForm.patchValue({ "memberId": "" })
            this.disalbedNameCard = false
            return
          } else {
            this.common.errorHandler(error);
          }
        } else {
          if (error.error.errorCode == 'TLC-973') {
            //console.log("Openerror", error)
            this.membershipDetailForm.patchValue({ "memberId": "" })
            this.disalbedNameCard = false
            return;
          } else {
            this.common.errorHandler(error);
          }
        }

      })
    } else {
      this.emailVerified = true;
      this.expandBlock = '';
      this.bindDataForNoMemberExist();
      this.membershipDetailForm.patchValue({
        benefitType: 'promocode',
        benefit: ''
      });
    }
  }

  bindDataForNoMemberExist() {
    const appData = this.appState.getAppData();
    this.membershipDetailForm.patchValue({
      'membershipNumber': ''
    });

    if (appData && appData.userDetails && appData.userDetails.outletId) {
      if (!this.membershipDetailForm.value.selectedOutlet) {
        this.membershipDetailForm.patchValue({
          'selectedOutlet': appData.userDetails.outletId
        });
      }
    }
  }

  getMembershipCardImages(response, customerSetIdentifier) {

    this.profileService.getSelectMembershipImageFunc({
      membershipType: customerSetIdentifier
    }).subscribe((aemResponse) => {
      if (aemResponse && aemResponse.ArrayList) {
        aemResponse = aemResponse.ArrayList;
        response.aemIdentifier.forEach(element => {
          element.customerSets.forEach(ele => {
            for (var i = 0; i < aemResponse.length; i++) {
              if (aemResponse[i].type === ele.customerSetIdentifier.substring(ele.customerSetIdentifier.lastIndexOf('/') + COUNT_NUMBER.COUNT_1, ele.customerSetIdentifier.length)) {
                ele[SPENDERS_PAGE.imageUrl] = aemResponse[i].cardImage;
              }
            }
          });
        });
        if (response.aemIdentifier.length === 1 && response.aemIdentifier[0].customerSets.length === 1) {
          this.getMemberProfileData(response.aemIdentifier[0].customerSets[0].membershipNumber);
        } else {
          this.openMembershipPopUp(response.aemIdentifier, response);
        }
      }
    })
  }

  async openMembershipPopUp(memberships, res) {
    const modal = await this.modalController.create({
      component: SelectMembershipComponent,
      cssClass: 'selectMembershipModal',
      componentProps: { 'memberships': memberships, 'response': res }
    });
    modal.present();
    modal.onWillDismiss().then((event) => {
      if (event && event.data && event.role) {
        this.getMemberProfileData(event.data.membershipNumber);
      } else {
        this.membershipDetailForm.patchValue({
          'selectedOutlet': this.getoutletid.userDetails.outletId
        });
        this.emailVerified = true;
        // this.expandBlock = 'mobile';
      }
    })
  }

  getMemberProfileData(membershipNumber: any) {
    this.certificateBenefits = [];
    this.cardBenefits = [];
    const appData = this.appState.getAppData();
    this.profileService.membershipNumber = membershipNumber;
    this.membershipNumber = membershipNumber;
    this.common.presentLoader('openReservation.membershipDetail');
    this.profileService.getmemberProfile().subscribe((res) => {
      this.common.dismissLoader();
      if (res) {
        this.memberProfileData = res;
        this.getBenefitsDetailFromAem(res.availableCertificates, 'certificate');
        this.getBenefitsDetailFromAem(res.cardBenefits, 'cardbenefit');
        let salutation = this.salutations.filter((data) => {
          return this.memberProfileData.memberDetails.honorofic == data.id || this.memberProfileData.memberDetails.honorofic.includes(data.id)
        });
       
        // this.membershipDetailForm.patchValue({
        //   'email': this.memberProfileData.memberDetails.emailId,
        //   'salutation': salutation.length ? salutation[0] : '',
        //   'firstName': this.memberProfileData.memberDetails.firstName,
        //   'lastName': this.memberProfileData.memberDetails.lastName,
        //   'membershipNumber': membershipNumber
        // });
        if (appData && appData.userDetails && appData.userDetails.outletId) {
          if (!this.membershipDetailForm.value.selectedOutlet) {
            this.membershipDetailForm.patchValue({
              'selectedOutlet': appData.userDetails.outletId
            });
          }
        }
        this.emailVerified = true;
        this.memberProfileOnOutletChange = true;
      }
    })
  }

  getBenefitsDetailFromAem(benefits: Array<any>, benefitsType: "certificate" | "cardbenefit") {
    let payload: IdentifierExporterPayload = {
      keys: []
    }
    switch (benefitsType) {
      case 'certificate':
        this.certificateBenefits = [];
        benefits.forEach((benefit) => {
          payload.keys.push({ identifier: benefit.benefitAemIdentifier, identifierType: benefitsType })
        });
        break;

      case 'cardbenefit':
        this.cardBenefits = [];
        benefits.forEach((benefit) => {
          payload.keys.push({ identifier: benefit.benefitAemIdentifier, identifierType: benefitsType })
        });
        break;
    }
    this.onBoardingService.aemDataApi(payload).subscribe(
      (res) => {
        if (res && res.data && res.data.items) {
          res = res.data.items;
          this.filterBenefitsFromAEM(res, benefits, benefitsType);
        }
      }
    );
  }

  filterBenefitsFromAEM(res, benefits: Array<any>, benefitsType: "certificate" | "cardbenefit") {
    switch (benefitsType) {
      case 'certificate':
        benefits.forEach((benefit) => {
          let aemBenefit = res.filter((data) => { return data.identifier.includes(benefit.benefitAemIdentifier) })
          if (aemBenefit.length > 0) {
            this.certificateBenefits.push({ sfdcData: benefit, aemData: aemBenefit[0] })
          }
        })
        break;

      case 'cardbenefit':
        benefits.forEach((benefit) => {
          let aemBenefit = res.filter((data) => { return data.identifier.includes(benefit.benefitAemIdentifier) })
          if (aemBenefit.length > 0) {
            this.cardBenefits.push({ sfdcData: benefit, aemData: aemBenefit[0] })
          }
        })
        break;
    }
    if (this.certificateBenefits.length || this.cardBenefits.length) {
      console.log("  if from certificate condition")
      this.membershipDetailForm.patchValue({
        benefitType: 'memberBenefits'
      });
      this.membershipDetailForm.patchValue({
        benefit: this.certificateBenefits.length ? 'Certificate' : 'Card'
      })
    } else {
      console.log("  else from promocode condition")
      this.membershipDetailForm.patchValue({
        benefitType: 'promocode'
      });
    }
  }

  getOutletList() {
    this.outletsList = []
    const appData = this.appState.getAppData();
    let employeeID = '';

    if (appData && appData.userDetails) {
      this.propertyId = appData.userDetails
      employeeID = appData.userDetails.sfid;
    }
    this.openReservationService.getOutletList(employeeID).then((res) => {
      if (res && res.property) {
        var outletsListt = res.property;
        outletsListt.forEach(element => {
          if (element.outletType == 'Restaurant' && element.propertyId == this.propertyId.hotelPropertyId && element.orderIn == false)
            this.outletsList.push(element)
        });
        console.log("this.outletsList", this.outletsList)
      }
    })
  }

  applyPromocode() {
    if (!this.membershipDetailForm.value.promocode) {
      return
    }
    let promocodeQueryStrig: ReservationPromocodeQueryString = {
      promoCode: this.membershipDetailForm.value.promocode,
      tx: 'Reservation'
    };
    this.common.presentLoader('openReservation.applyPromocode');
    this.openReservationService.applyPromocode(promocodeQueryStrig).then((res) => {
      this.common.dismissLoader();
      if (res) {
        this.promocodeDetails = res
        this.membershipDetailForm.patchValue({
          "promocodeDetails": res
        })
      }
    });
  }
  ChangePromo(value) {
    //console.log("value", value)
    this.membershipDetailForm.patchValue({
      "promocodeDetails": ""
    })
  }

  changeFocus(type: 'email' | 'mobile' | 'name' | 'roomNumber') {
    console.log("changeFocus", type);
    switch (type) {
      case 'email':
       
          this.expandBlock =  'email';
        
    
        // if (this.mobileVerified) {
        //   this.expandBlock = this.expandBlock != type ? type : '';
        // } else {
        //   this.expandBlock = '';
        //   this.translate.get('openReservation.verifyMobileFirst').subscribe((res) => {
        //     this.common.presentToast(res)
        //   });
        // }
        break;

      case 'mobile':
        this.expandBlock = type;
        break;

      case 'name':
        this.expandBlock =  type;
        this.namefield = false;
        // if (this.mobileVerified) {
        //   this.expandBlock =  'name';
        // } else {
        //   this.expandBlock = '';
        //   this.translate.get('openReservation.verifyMobileFirst').subscribe((res) => {
        //     this.common.presentToast(res)
        //   });
        // }
        break;

        case 'roomNumber':
          this.roomcheck = false;

        break;
    }
  }

  async selectBenefits(benefitsType: 'promocode' | 'memberBenefits') {
    console.log("selectBenefits", benefitsType);
    this.expandBlock = '';
    this.membershipDetailForm.patchValue({
      "benefitType": benefitsType
    });
    if (benefitsType == 'promocode') {
      // this.benefitsTypeSelected = true
      this.membershipDetailForm.patchValue({
        "memberBenefit": ""
      })
    } else {
      // this.benefitsTypeSelected = false
      this.membershipDetailForm.patchValue({
        "promocode": '',
        "promocodeDetails": ''
      });
    }
  }

  expandBenefits(promocode?:any) {
  
    // if(promocode){
    //   this.selectBenefits(promocode);

    // }
    console.log("expandBenefits", promocode);
    if (this.benefitExpanded) {
      this.benefitExpanded = false;
      $(`#${this.membershipDetailForm.value.benefitType}`).slideUp(500);
      $(`#selectBenefits`).slideUp(500);
    } else {
      this.benefitExpanded = true;
      $(`#${this.membershipDetailForm.value.benefitType}`).slideDown(500);
      $(`#selectBenefits`).slideDown(500);
    }
  }

  selectBenefit(benefit, type) {
    if(type == "cardBenefit"){
      this.membershipDetailForm.patchValue({
        "benefit": type
      });
    }
    else {
      this.membershipDetailForm.patchValue({
        "benefit": type
      });
    }
    
    console.log("i am called for select benefit", benefit)
    if (this.memberProfileData.membershipDetails.membershipStatus && this.memberProfileData.membershipDetails.membershipStatus != 'Active') {
      this.alertMsg()
      return;
    }

    this.membershipDetailForm.patchValue({
      "memberBenefit": benefit
    });
    // let navigationExtras: NavigationExtras = {
    // state: { membershipDetails: JSON.stringify(this.membershipDetailForm.value) }
    // }
    // this.router.navigate(['reservation'], navigationExtras);
    console.log("this.this.membershipDetailForm.valu", this.membershipDetailForm.value);
    this.sendDetailsmember = JSON.stringify(this.membershipDetailForm.value)
    this.enablecreateReservation = true



  }

  goToReservation() {
    console.log("i am called for goToReservation")
    if (this.membershipDetailForm.value.promocode && !this.membershipDetailForm.value.promocodeDetails) {
      this.translate.get('openReservation.pleaseApplyPromocode').subscribe((res) => {
        this.common.presentToast(res)
      });
      return;
    }
    // let navigationExtras: NavigationExtras = {
    // state: { membershipDetails: JSON.stringify(this.membershipDetailForm.value) }
    // }

    // this.router.navigate(['reservation'], navigationExtras);
    this.sendDetailsmember = JSON.stringify(this.membershipDetailForm.value)
    this.enablecreateReservation = true

  }

  openBack() {
    console.log("hit back")
    this.common.outletIDD = ""
  }

  async alertMsg() {
    var msgShow
    if (this.memberProfileData.membershipDetails.membershipStatus == 'Pending Activation') {
      msgShow = "This membership is not activated. Request the member to download the App and activate membership."
    } else {
      msgShow = "This membership has lapsed. Request the member to renew membership."
    }
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'alertCustomCss',
      message: msgShow,
      buttons: [
        {
          text: 'OK',
          handler: (blah) => {
            this.alertCtrl.dismiss();
            console.log('Confirm:');
          }
        }],

    });
    await alert.present();
  }

  backToMakereservation() {
    console.log("called for backToMakereservation");
    this.enablecreateReservation = false
  }



  onInputTwo(type: 'email' | 'mobile') {
    switch (type) {
      case 'email':
        this.sendType = 'email'
        if (this.membershipDetailForm.controls['email'].valid) {
          this.onBoardingService.activateApiMemberData = {
            email: this.membershipDetailForm.value.email
          }
        }
        break;

      case 'mobile':
        this.sendType = 'mobile'
        if ((!this.membershipDetailForm.errors)) {
          this.onBoardingService.activateApiMemberData = {
            mobile: this.membershipDetailForm.value.countryCodeSelected.dialCode + this.membershipDetailForm.value.mobile
          }
        } else {
          this.membershipDetailForm.patchValue({
            email: '',
            firstName: '',
            lastName: '',
            membershipNumber: '',
            // selectedOutlet: '',
            promocode: '',
            benefitType: '',
            benefit: '',
            memberBenefit: '',
            promocodeDetails: ''
          })
          this.mobileVerified = false;
          this.emailVerified = false;
          this.memberProfileData = null;
          this.cardBenefits = [];
          this.certificateBenefits = [];
          this.membershipNumber = '';
          this.memberProfileOnOutletChange = false;
          this.expandBlock = 'mobile';
          this.benefitExpanded = false;
        }
        break;

      default:
        break;
    }
  }



}