import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { Platform, MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/services/event/event.service';
import { CUSTOM_SELECT_ICON, HOURS, JAVASCRIPT, MINUTES, OPEN_RESERVATION_PAGE, ReservationCelebrations, TITLE, SET_DATE_MODAL } from 'src/app/app.constants';
import { OpenReservationService } from 'src/app/services/open-reservation/open-reservation.service';
import { DatePipe } from '@angular/common';
import { MbscDatepicker } from '@mobiscroll/angular';
import { beveragePreferencesPrototype, foodPreferencesPrototype, PayloadForReservation, ReservationPayload } from 'src/app/pages/reservation/reservation.model';

declare var $: any;
@Component({
  selector: 'app-createreservation',
  templateUrl: './createreservation.component.html',
  styleUrls: ['./createreservation.component.scss'],
})
export class CreatereservationComponent implements OnInit {
  @Input() sendMembershipDetailsObj: any;
  membershipDetails: any;
  reservationDateTime: Date;
  minDate: any;
  amOrPm: 'AM' | 'PM' = 'PM';
  outletDetails: any;
  benefitDetails: any;
  outletTimeZone: number;
  minutes: string[];
  hours: string[];
  hour: string;
  minute: string;
  selectedDate: string;
  numberOfGuest: number = 1;
  guestCountPara: string;
  countBuffer: number;
  guestCountModalOpenedForMinB: boolean;
  guestCountModalOpenedForMaxB: boolean;
  guestCountModalOpenedForMaxO: boolean;
  guestCountParaForOutlet: string;
  benefitApplicable: boolean = false;
  outletClosingTime: Date;
  outletOpeningTime: Date;
  outletNotOperationPara: string;
  reservationNotPossible: boolean = true;
  currentDateTimeReservationPara: string;
  todayDate: string;
  tomorrowDate: string;
  celebration: string;
  celebrations: { appBirthday: string; appAnniversary: string; appOther: string; };
  foodPreferences: Array<any>;
  beveragePreferences: Array<any>;
  selectedFoodPreference: Array<string> = [];
  selectedBeveragePreference: Array<string> = [];
  specialRequest: string;
  certificateNotApplicablePara: string;
  selectedCelebDescription: string = "";
  reservationConfirmationPara: string;
  confirmationNumberPara: any;
  hoursInterfaceOptions = {
    header: '',
    translucent: true
  };
  minutesInterfaceOptions = {
    header: '',
    translucent: true
  };
  monthShortNames: string;
  @ViewChild('picker', { static: false })
  pickerInst: MbscDatepicker;
  selectTablePreferences: any;

  constructor(
    public appState: AppStateService,
    private router: Router,
    public commonService: CommonService,
    public plt: Platform,
    private translate: TranslateService,
    public events: EventService,
    private openReservationService: OpenReservationService,
    private datePipe: DatePipe,
    public menu: MenuController,
  ) {
    this.minutes = MINUTES;
    this.hours = HOURS;
    this.hour = this.hours[this.hours.length - 2];
    this.minute = this.minutes[0];
    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.tomorrowDate = this.datePipe.transform(new Date(new Date().setDate(new Date().getDate() + 1)), 'yyyy-MM-dd');
    this.celebrations = ReservationCelebrations;
  }

  openPicker() {
    this.pickerInst.open();
    
  };

  ngOnInit() {
    this.menu.enable(false);
    this.membershipDetails = JSON.parse(this.sendMembershipDetailsObj)
       console.log("membershipDetails", this.membershipDetails)
    this.translate.get(SET_DATE_MODAL.customMonth).subscribe((res: string) => {
      //console.log("date", res)
      this.monthShortNames = res
    });
  }

  // Custom Select Icon
  ngAfterContentInit() {
    console.log("ngAfterContentInit")
    this.getOutletDetails();
    this.translate.get('openReservation.hours').subscribe((res) => {
      this.hoursInterfaceOptions.header = res;
    });
    this.translate.get('openReservation.minutes').subscribe((res) => {
      this.minutesInterfaceOptions.header = res;
    });

    const appData = this.appState.getAppData();
    if (appData && appData.appInitData) {
      this.foodPreferences = appData.appInitData.foodPreferences || foodPreferencesPrototype;
      this.beveragePreferences = appData.appInitData.beveragePreferences || beveragePreferencesPrototype;
    }
    document.getElementById("guestCount").classList.remove(JAVASCRIPT.SHOW)
    document.getElementById("guestCountForOutlet").classList.remove(JAVASCRIPT.SHOW)
    const ionSelects = document.querySelectorAll(CUSTOM_SELECT_ICON.ionSelect);
    ionSelects.forEach((sel) => {
      sel.shadowRoot.querySelectorAll(CUSTOM_SELECT_ICON.selectIconInner)
        .forEach((elem) => {
          elem.setAttribute(JAVASCRIPT.STYLE, JAVASCRIPT.DISPLAY_NONE);
        });
    });

    this.plt.ready().then((readySource) => {
      this.translate.get(OPEN_RESERVATION_PAGE.OPEN_RESERVATION).subscribe((res: string) => {
        this.events.publish(TITLE.TITLE, res);
      });
    });
  }

  getOutletDetails() {
    console.log("this.membershipDetails.selectedOutlet",this.membershipDetails.selectedOutlet);
    this.translate.get('openReservation.fetching').subscribe(
      (res) => {
        this.commonService.presentLoader(res);
      }
    );
    this.openReservationService.getDetailsOfOutletAndBenefit(this.membershipDetails.selectedOutlet, 'Outlet').then(
      (outletDetails) => {
        this.commonService.dismissLoader();
        if (outletDetails) {
          this.outletDetails = outletDetails;
          this.outletTimeZone = this.outletDetails.location.outletTimeZone;
          if (this.membershipDetails.memberBenefit != "" || this.membershipDetails.promocodeDetails != "") {
            console.log("hit")
            this.getBenefitDetails();
          }
          //console.log('outlet details ====>>>', outletDetails);
        }
      }
    )
  }

  getOutletOpeningAndClosingTime() {
    let outletOpeningHour = Number(this.outletDetails.openingTime.split(':')[0]);
    let outletOpeningMinute = Number(this.outletDetails.openingTime.split(':')[1]);
    let outletClosingHour = Number(this.outletDetails.closingTime.split(':')[0]);
    let outletClosingMinute = Number(this.outletDetails.closingTime.split(':')[1]);
    let fullYear = this.reservationDateTime.getFullYear();
    let month = this.reservationDateTime.getMonth();
    let date = this.reservationDateTime.getDate();
    if (outletOpeningHour >= outletClosingHour) {
      let openingTime = new Date(fullYear, month, date, outletOpeningHour, outletOpeningMinute, 0, 0);
      let closingTime = new Date(fullYear, month, date, outletClosingHour, outletClosingMinute, 0, 0);
      this.outletOpeningTime = openingTime;
      this.outletClosingTime = new Date(closingTime.setDate(closingTime.getDate() + 1));
    } else {
      this.outletOpeningTime = new Date(fullYear, month, date, outletOpeningHour, outletOpeningMinute, 0, 0);
      this.outletClosingTime = new Date(fullYear, month, date, outletClosingHour, outletClosingMinute, 0, 0);
    }
    this.checkRestrictionForOutlet();
    //console.log('outlet opening-closing ===>>>', this.outletOpeningTime, this.outletClosingTime);
  }

  getBenefitDetails() {
    if ((this.membershipDetails.benefit && this.membershipDetails.memberBenefit && this.membershipDetails.memberBenefit.sfdcData) ||
      this.membershipDetails.benefitType == 'promocode'
    ) {
      this.translate.get('openReservation.fetching').subscribe(
        (res) => {
          this.commonService.presentLoader(res);
        }
      );
      let id = ''

      switch (this.membershipDetails.benefitType) {
        case "memberBenefits":

          switch (this.membershipDetails.benefit) {
            case "Certificate":
              id = this.membershipDetails.memberBenefit.sfdcData.certificateBenefitId
              break;

            case "Card":
              id = this.membershipDetails.memberBenefit.sfdcData.cardBenefitId
              break;
          }

          break;

        case "promocode":
          if (this.membershipDetails.promocode && this.membershipDetails.promocodeDetails) {
            id = this.membershipDetails.promocode;
            this.membershipDetails.benefit = 'Promocode'
          }
          break;
      }


      // let id = this.membershipDetails.benefit == 'Certificate' ?
      //   this.membershipDetails.memberBenefit.sfdcData.certificateBenefitId : this.membershipDetails.memberBenefit.sfdcData.cardBenefitId
      //console.log(id, this.membershipDetails.benefit)
      if (id == '' && this.membershipDetails.benefit == '') {
        this.commonService.dismissLoader();
        return;
      }
      this.openReservationService.getDetailsOfOutletAndBenefit(id, this.membershipDetails.benefit).then((benefitDetail) => {
        this.commonService.dismissLoader();
        if (benefitDetail) {
          this.benefitApplicable = true;
          this.benefitDetails = benefitDetail;
          this.numberOfGuest = Number(benefitDetail.minAdmitLimitGuest) || 1;
          this.checkExclusionDate
          //console.log('outlet details ====>>>', benefitDetail);
        }
      })
    } else {
      this.benefitApplicable = false;
      this.numberOfGuest = Number(this.outletDetails.minCoversAllowedGuest) || 1;
    }
  }

  getHourAccTo24HrsClock() {
    let hour = Number(this.hour);
    switch (this.amOrPm) {
      case 'AM':
        if (Number(this.hour) == 12) {
          hour = 0
        }
        break;

      case 'PM':
        if (Number(this.hour) < 12) {
          hour = Number(this.hour) + 12;
        }
        break;
    }
    return hour;
  }

  getDate(dateType: 'today' | 'tomorrow' | 'other', date?: any) {
    
    let newDate: Date = null;
    switch (dateType) {
      case 'today':
        newDate = new Date(new Date().setHours(this.getHourAccTo24HrsClock(), Number(this.minute), 0, 0));
        console.log("newdate clik=>>", newDate)
        this.reservationDateTime = newDate;
        break;

      case 'tomorrow':
        newDate = new Date(new Date().setDate(new Date().getDate() + 1));
        this.reservationDateTime = new Date(newDate.setHours(this.getHourAccTo24HrsClock(), Number(this.minute), 0, 0));
        break;

      case 'other':
       
        if (date.value) {
      
          var checkDate = new Date(date.value)
          var updateValid = new Date(checkDate.getTime() - (checkDate.getTimezoneOffset() * 60000)).toISOString();
          console.log("updateValid==> ",updateValid )
          date = updateValid.split('T')[0].split('-');
          console.log("date==> ",date )
          this.reservationDateTime = new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), this.getHourAccTo24HrsClock(), Number(this.minute), 0, 0);
        }
        break;
    }
    this.selectedDate = this.datePipe.transform(this.reservationDateTime, 'yyyy-MM-dd')
    this.getOutletOpeningAndClosingTime();
    //console.log(this.reservationDateTime, this.selectedDate);
  }

  getTime(type: 'hour' | 'minute' | 'am-pm') {
    if (this.reservationDateTime) {
      switch (type) {
        case 'hour':
          this.reservationDateTime = new Date(this.reservationDateTime.setHours(this.getHourAccTo24HrsClock()))
          break;

        case 'minute':
          this.reservationDateTime = new Date(this.reservationDateTime.setMinutes(Number(this.minute)))
          break;

        case 'am-pm':
          this.reservationDateTime = new Date(this.reservationDateTime.setHours(this.getHourAccTo24HrsClock()))
          break;
      }
      //console.log(this.reservationDateTime);
      this.checkRestrictionForOutlet();
    }
  }

  countGuest(count) {
    if (!(count < 1)) {
      if (this.benefitDetails && Number(this.outletDetails.maxCoversAllowedGuest) > Number(this.benefitDetails.maxAdmitLimitGuest)) {
        this.checkGuestCountOnTheBasisBenefit(count);
      } else {
        this.checkGuestCountOnTheBasisOfOutlet(count);
      }
    }
  }

  checkGuestCountOnTheBasisBenefit(count: number) {
    if (count >= Number(this.benefitDetails.minAdmitLimitGuest) && count <= Number(this.benefitDetails.maxAdmitLimitGuest)) {
      this.numberOfGuest = count;
      this.benefitApplicable = true;
    } else {
      this.benefitApplicable = false;
      if (count < Number(this.benefitDetails.minAdmitLimitGuest)) {
        if (this.guestCountModalOpenedForMinB) {
          this.numberOfGuest = count
        } else {
          this.translate.get('openReservation.guestCountMinValidation', { 'count': this.benefitDetails.minAdmitLimitGuest }).subscribe((res: string) => {
            this.guestCountPara = res;
            document.getElementById("guestCount").classList.add(JAVASCRIPT.SHOW);
            this.countBuffer = count;
            this.guestCountModalOpenedForMinB = true;
          });
        }
      } else if (count > Number(this.benefitDetails.maxAdmitLimitGuest) && count < Number(this.outletDetails.maxCoversAllowedGuest)) {
        if (this.guestCountModalOpenedForMaxB) {
          this.numberOfGuest = count
        } else {
          this.translate.get('openReservation.guestCountMaxValidation', { 'count': this.benefitDetails.maxAdmitLimitGuest }).subscribe((res: string) => {
            this.guestCountPara = res;
            document.getElementById("guestCount").classList.add(JAVASCRIPT.SHOW);
            this.countBuffer = count;
            this.guestCountModalOpenedForMaxB = true;
          });
        }
      } else {
        this.checkGuestCountOnTheBasisOfOutlet(count);
      }
    }
  }

  checkGuestCountOnTheBasisOfOutlet(count: number) {
    if (count > this.outletDetails.maxCoversAllowedGuest) {
      if (this.guestCountModalOpenedForMaxO) {
        this.numberOfGuest = count;
      } else {
        this.translate.get('openReservation.guest_vali').subscribe((res: string) => {
          this.guestCountParaForOutlet = res;
          document.getElementById("guestCountForOutlet").classList.add(JAVASCRIPT.SHOW);
          this.countBuffer = count;
          this.guestCountModalOpenedForMaxO = true;
        });
      }
    } else {
      this.numberOfGuest = count;
    }
  }

  closeGuestCountModal(cont: boolean) {
    if (cont) {
      this.numberOfGuest = this.countBuffer;
    }
    document.getElementById("guestCount").classList.remove(JAVASCRIPT.SHOW);
  }

  closeGuestCountModalForOutlet(cont: boolean) {
    if (cont) {
      this.numberOfGuest = this.countBuffer;
    }
    document.getElementById("guestCountForOutlet").classList.remove(JAVASCRIPT.SHOW);
  }

  closeOutletNotOperationModal() {
    document.getElementById("outletNotOperationModal").classList.remove(JAVASCRIPT.SHOW);
  }

  closeCurrentDateTimeReservationModal() {
    document.getElementById("currentDateTimeReservationModal").classList.remove(JAVASCRIPT.SHOW);
  }

  checkRestrictionForOutlet() {
    if (this.reservationDateTime >= this.outletOpeningTime && this.reservationDateTime < this.outletClosingTime) {
      const appData = this.appState.getAppData();
      let restrictionHour = 0;
      let restrictionMinute = 15;

      if (appData && appData.appInitData && appData.appInitData.reservationRestrictionTime) {
        restrictionHour = Number(appData.appInitData.reservationRestrictionTime.hours);
        restrictionMinute = Number(appData.appInitData.reservationRestrictionTime.minutes);
      }
      let currentDate = new Date(new Date().setHours(new Date().getHours() + restrictionHour, (new Date().getMinutes() + new Date().getTimezoneOffset() - (this.outletTimeZone || 0)) + restrictionMinute, 0, 0));
      //console.log("Current time on the basis of outlet time zone and reservation restriction time =====>>>", currentDate);
      if (this.reservationDateTime >= currentDate) {
        if (this.outletDetails.exclusionDates.length) {
          let exclusionDatesSatified = this.checkExclusionDate(JSON.stringify(this.outletDetails.exclusionDates));
          if (exclusionDatesSatified.length) {
            this.reservationNotPossible = true;
            this.translate.get('openReservation.two_time_validation').subscribe((res: string) => {
              this.currentDateTimeReservationPara = res;
              document.getElementById("currentDateTimeReservationModal").classList.add(JAVASCRIPT.SHOW);
            });
          } else {
            this.reservationNotPossible = false;
          }
        } else {
          this.reservationNotPossible = false;
        }
      } else {
        this.translate.get('openReservation.two_time_validation').subscribe((res: string) => {
          this.currentDateTimeReservationPara = res;
          document.getElementById("currentDateTimeReservationModal").classList.add(JAVASCRIPT.SHOW);
          this.reservationNotPossible = true;
        });
      }
    } else {
      this.translate.get('openReservation.valid_time_validation').subscribe((res: string) => {
        this.outletNotOperationPara = res;
        document.getElementById("outletNotOperationModal").classList.add(JAVASCRIPT.SHOW);
        this.reservationNotPossible = true;
      });
    }
  }

  checkExclusionDate(exclusionDates: string) {
    let parsedExclusionDates: Array<any> = JSON.parse(exclusionDates);
    let selectedDate = new Date(this.reservationDateTime.getFullYear(), this.reservationDateTime.getMonth(), this.reservationDateTime.getDate(), 0, 0, 0, 0);
    let filteredExclusions = parsedExclusionDates.filter(
      (date) => {
        let startDate = new Date(Number(date.StartDate.split('-')[2]), Number(date.StartDate.split('-')[0]) - 1, Number(date.StartDate.split('-')[1]), 0, 0, 0, 0);
        let endDate = new Date(Number(date.endDate.split('-')[2]), Number(date.endDate.split('-')[0]) - 1, Number(date.endDate.split('-')[1]), 0, 0, 0, 0);
        if (selectedDate >= startDate && selectedDate <= endDate && date.Validity.length) {
          date.Validity = date.Validity.filter(
            (val) => {
              val.Days = val.Days.filter((day) => { return selectedDate.getDay() == day });
              if (val.Days.length) {
                val.Time = val.Time.filter(
                  (time) => {
                    let fromTimeHour = Number(time.fromTime.split(':')[0]);
                    let fromTimeMinute = Number(time.fromTime.split(':')[1]);
                    let endTimeHour = Number(time.endTime.split(':')[0]);
                    let endTimeMinute = Number(time.endTime.split(':')[1]);
                    let fullYear = this.reservationDateTime.getFullYear();
                    let month = this.reservationDateTime.getMonth();
                    let date = this.reservationDateTime.getDate();
                    let startTime: Date = null;
                    let endTime: Date = null;
                    if (fromTimeHour >= endTimeHour) {
                      let openingTime = new Date(fullYear, month, date, fromTimeHour, fromTimeMinute, 0, 0);
                      let closingTime = new Date(fullYear, month, date, endTimeHour, endTimeMinute, 0, 0);
                      startTime = openingTime;
                      endTime = new Date(closingTime.setDate(closingTime.getDate() + 1));
                    } else {
                      startTime = new Date(fullYear, month, date, fromTimeHour, fromTimeMinute, 0, 0);
                      endTime = new Date(fullYear, month, date, endTimeHour, endTimeMinute, 0, 0);
                    }

                    if (startTime && endTime) {
                      return this.reservationDateTime >= startTime && this.reservationDateTime <= endTime
                    }
                  }
                )
              }
              return val.Days.length && val.Time.length;
            }
          );
          return date.Validity.length ? true : false;
        }
      }
    );

    //console.log('exclusion date satisfied ====>>>', filteredExclusions);
    return filteredExclusions;
  }

  selectCelebration(celebration: string) {
    this.celebration = celebration
  }

  addPreference(value: any, type: 'beverage' | 'food' | 'tablePre') {
    switch (type) {
      case 'beverage':
        if (this.selectedBeveragePreference.find((beverage) => { return beverage == value.id })) {
          this.selectedBeveragePreference.splice(this.selectedBeveragePreference.findIndex((beverage) => { return beverage == value.id }), 1);
        } else {
          this.selectedBeveragePreference.push(value.id);
        }
        break;

      case 'food':
        if (this.selectedFoodPreference.find((food) => { return food == value.id })) {
          this.selectedFoodPreference.splice(this.selectedFoodPreference.findIndex((food) => { return food == value.id }), 1);
        } else {
          this.selectedFoodPreference.push(value.id);
        }
        break;

        case 'tablePre':
        this.selectTablePreferences = value
        console.log("this.selectTablePreferences", this.selectTablePreferences)
        break;
    }

  }


  openPreference(id: "foodPreference" | "beveragePreference" | "tablePreference", arrowID: "food-arrow" | "beverage-arrow" | "table-arrow") {
    $(`#${id}`).slideToggle(500);
    let classList = document.getElementById(arrowID).classList.value.split(' ');
    if (classList[0] == 'ca-down-arrow-yellow-icon') {
      document.getElementById(arrowID).classList.remove('ca-down-arrow-yellow-icon')
      document.getElementById(arrowID).classList.add('ca-up-arrow-yellow-icon')
    } else {
      document.getElementById(arrowID).classList.add('ca-down-arrow-yellow-icon')
      document.getElementById(arrowID).classList.remove('ca-up-arrow-yellow-icon')
    }
  }


  checkRestrictionForBenefits() {
    if (this.benefitDetails && this.benefitApplicable) {
      let exclusionDatesSatified = this.checkExclusionDate(JSON.stringify(this.benefitDetails.exclusionDates))
      if (exclusionDatesSatified.length) {
        this.translate.get('openReservation.restricted_time_validation').subscribe((res: string) => {
          this.certificateNotApplicablePara = res;
          document.getElementById("certificateNotApplicableModal").classList.add(JAVASCRIPT.SHOW);
        });
      } else {
        this.doReservation();
      }
    } else {
      this.doReservation();
    }
  }

  closeCertificateNotApplicableModal(cont: boolean) {
    document.getElementById("certificateNotApplicableModal").classList.remove(JAVASCRIPT.SHOW);
    if (cont) {
      this.benefitApplicable = false;
      this.doReservation();
    }
  }

  doReservation() {
    var identifier
    if((this.membershipDetails.mobile && this.membershipDetails.email) || this.membershipDetails.mobile && this.membershipDetails.email && this.membershipDetails.roomNumber ){
      identifier = 'existingApi'
  
    }
    else {
      identifier = 'newApi'
    }

    let payload: PayloadForReservation = {
      memberDetails: {
        emailId: this.membershipDetails.email,
        honorofic: this.membershipDetails.salutation.id || this.membershipDetails.salutation,
        firstName: this.membershipDetails.firstName,
        lastName: this.membershipDetails.lastName,
        mobileNo:  this.membershipDetails.mobile ? this.membershipDetails.countryCodeSelected.dialCode + this.membershipDetails.mobile : ''
       
        
      },
      memberId: this.membershipDetails.memberId,
      roomNumber : this.membershipDetails.roomNumber ? (this.membershipDetails.roomNumber) : '',
      reservationDetails: {
        reservationDate: this.datePipe.transform(this.reservationDateTime, 'yyyy-MM-dd'),
        reservationTime: this.datePipe.transform(this.reservationDateTime, 'HH:mm')
      },
      guestCount: this.numberOfGuest.toString(),
      foodPreferences: this.selectedFoodPreference,
      beveragePreferences: this.selectedBeveragePreference,
      selectedTablePreference: this.selectTablePreferences,
      celebration: {
        description: this.selectedCelebDescription || "",
        occasion: this.celebration || ""
      },
      specialRequest: this.specialRequest
    }


    switch (this.membershipDetails.benefitType) {
      case "memberBenefits":
        if (this.benefitApplicable) {
          switch (this.membershipDetails.benefit) {
            case "Certificate":
              payload.benefitCode = this.benefitDetails.certificateID;
              break;

            case "Card":
              payload.benefitCode = this.benefitDetails.cardBenefitID;
              break;
          }
        }
        break;

      case "promocode":
        if (this.membershipDetails.promocode && this.membershipDetails.promocodeDetails && this.benefitApplicable) {
          payload.promoCode = this.membershipDetails.promocode;
        }
        break;
    }

    let reservationPayload = new ReservationPayload(payload);
    let queryString: any = { outletID: this.outletDetails.outletID };
    if (this.membershipDetails.membershipNumber) {
      queryString.membershipNumber = this.membershipDetails.membershipNumber
    }
    this.translate.get('openReservation.pleaseWait').subscribe((res) => {
      this.commonService.presentLoader(res);
    });
    this.openReservationService.doReservation(reservationPayload, queryString, identifier).then(
      (res) => {
        this.commonService.dismissLoader();
        if (res) {
          this.openReservationService.outletID = ""; //This needs to be reset after the reservation is successful
          this.commonService.outletIDD = ""
          var getTypemsg:string
          if(this.membershipDetails.mobile =='' && this.membershipDetails.email=='' ) {
            getTypemsg = "openReservation.confirmationNumberWalk"
          } 
          if(this.membershipDetails.mobile) {
            getTypemsg = "openReservation.confirmationNumberMobileWalk"
          }
          if(this.membershipDetails.mobile && this.membershipDetails.email) {
            getTypemsg = "openReservation.confirmationNumber"
          }
          if(this.membershipDetails.email) {
            getTypemsg = "openReservation.confirmationNumber"
          }
          this.translate.get(
            'openReservation.reservationConfirmedPara',
            { outletName: this.outletDetails.outletName }
            
          ).subscribe((reservationConfirmationPara: string) => {
            this.translate.get(getTypemsg, { confirmationNumber: res.confirmationNumber }).subscribe(
              (confirmation) => {
                this.confirmationNumberPara = confirmation;
              });
            this.reservationConfirmationPara = reservationConfirmationPara;
            document.getElementById("confirmReservation").classList.add(JAVASCRIPT.SHOW);
          });
        }
      }
    )
  }

  proceed() {
    console.log("got it");
    document.getElementById("confirmReservation").classList.add(JAVASCRIPT.HIDE);
    // this.router.navigate(['web-dashboard'])
    window.location.reload();
  }

}

